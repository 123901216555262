<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        VM List
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-4" dark @click="showMaintenancePopup()"> <v-icon>mdi-bell-outline</v-icon> Send Support Announcement</v-btn>
        <v-btn color="primary" dark @click="showDialog = true"> <v-icon>mdi-plus</v-icon> Register new VM</v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col md="3" sm="4" cols="12">
          <AutoCompleteField
                :items="teammembers"
                item-title="FullName"
                item-value="Id"
                v-model="selectedTeammemberFilter"
                :firstInactiveId="firstInactiveTeammemberId"
                label="Team Member"
                outlined
                dense
                hide-details
              />
        </v-col>
        <v-col md="3" sm="4" cols="12">
          <AutoCompleteField
            :items="customers"
            item-title="FullName"
            item-value="id"
            v-model="selectedCustomerFilter"
            label="Customer"
            outlined
            dense
            hide-details
          ></AutoCompleteField>
        </v-col>
        <v-col md="3" sm="4" cols="12">
          <TextField outlined dense label="Public IP" placeholder="Public IP" v-model="selectedPublicIpFilter" />
        </v-col>
        <v-col md="3" sm="4" cols="12" class="text-right pt-12">
          <v-btn class="mr-3" @click="clearFilter">
            <v-icon left>mdi-close</v-icon>
            clear Filter
          </v-btn>
          <v-btn color="primary" @click="fetchVMs">
            <v-icon left>mdi-check</v-icon>
            Filter
          </v-btn>
        </v-col>
      </v-row>
      <v-form ref="frmList" lazy-validation>
        <v-btn class="secondary" @click="removeSelection"> <v-icon>mdi-tag-remove-outline</v-icon> Deselect all</v-btn>
        <v-data-table-server
          :items="vmList"
          :headers="headers"
          :loading="loading"
          :page="page"
          :items-per-page="numberOfPages"
          @update:options="(event: any) => tableOptions = event"
          :items-length="totalItemsCount"
        >
          <template v-slot:item="{ item }">
            <tr :class="getClassByStatus(item)">
              <td>
                <v-checkbox class="vm-checkbox" v-model="item.IsSelected"></v-checkbox>
              </td>
              <td>{{ getTMName(item.OwnerId) }}</td>
              <td>{{ item.Username }}</td>
              <td>{{ item.Password }}</td>
              <td>{{ item.PublicIP }}</td>
              <td>{{ item.PrivateIP }}</td>
              <td>
                <v-chip v-if="item.Status" :color="getStatusColor(item.Status)" dark>
                  {{ item.Status }}
                </v-chip>
              </td>
              <td>
                <v-menu offset-y location="bottom" scroll-strategy="close">
                  <template v-slot:activator="{ props }">
                    <v-btn size="small" variant="text" color="black" v-bind="props" icon="mdi-dots-vertical" />
                  </template>
                  <v-list>
                    <v-list-item @click="editVM(item)" prepend-icon="mdi-pencil-outline" title="Edit" />
                    <v-list-item @click="showActivityWatch(item)" prepend-icon="mdi-chart-line" title="Show Activity Watch" />
                    <v-list-item @click="showWazuhDashboard(item)" prepend-icon="mdi-shield-check" title="Show Wazuh Report" />
                    <v-list-item @click="showVmActionLog(item)" prepend-icon="mdi-list-box" title="Show Action Log" />
                    <!-- <v-list-item @click="openTerminate(item)" prepend-icon="mdi-cancel" title="Status" /> -->
                    <v-menu offset-y location="left">
                      <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props">
                          <template v-slot:prepend>
                            <v-icon small>mdi-content-save-cog</v-icon>
                          </template>
                          <v-list-item-title>Status</v-list-item-title>
                        </v-list-item>
                      </template>

                      <v-list>
                        <v-list-item v-for="statusName in vmStatuses" @click="openStatusPopup(item, statusName)">
                          <v-list-item-title>{{ statusName }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table-server>
      </v-form>
    </v-card-text>
    <v-dialog width="auto" v-model="showDialog" @keypress.esc="close()">
      <CreateOrEditVM :teammembers="teammembers" @cancel="close()" @save="save" :loading="loading":vmModel="selectedVM"></CreateOrEditVM>
    </v-dialog>
    <v-dialog width="600" v-if="showMaintenanceDialog" v-model="showMaintenanceDialog" @keypress.esc="close()">
      <VdSupport :teammembers="teammembers" @cancel="close()" @sendRequest="createNotification" :vmList="selectedVmList"></VdSupport>
    </v-dialog>
    <v-dialog persistent v-if="showStatusPopup" v-model="showStatusPopup" max-width="600" @keydown.esc="close()">
      <ChangeStatus :status="selectedVmStatus" :vmId="selectedVmId" @close="close()" @confirmed="changeVmStatus"></ChangeStatus>
    </v-dialog>
    <v-dialog persistent v-if="showActionLogPopup" v-model="showActionLogPopup" max-width="600" @keydown.esc="close()">
      <ActionLogList :vmId="selectedVmId" @close="close()"></ActionLogList>
    </v-dialog>
    <v-dialog persistent v-if="showActivityWatchDialog" v-model="showActivityWatchDialog" fullscreen transition="dialog-bottom-transition" @keydown.esc="close()">
      <ActivityWatch :vmId="selectedVmId" @close="close()"></ActivityWatch>
    </v-dialog>
    <v-dialog persistent v-if="showWazuhDialog" v-model="showWazuhDialog" fullscreen transition="dialog-bottom-transition" @keydown.esc="close()">
      <Wazuh :vmId="selectedVmId" @close="close()"></Wazuh>
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import { TeamMemberSort, TeamMemberSummarySort } from "shared-components/src/models/Teammember";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import AppHelper from "shared-components/src/utils/AppHelper";
import CreateOrEditVM from "./CreateOrEditVM.vue";
import ChangeStatus from "./ChangeStatus.vue";
import ActionLogList from "./ActionLogList.vue";
import VdSupport from "shared-components/src/components/Vm/VdSupport.vue";
import { VM } from "shared-components/src/models/VM";
import VMService from "shared-components/src/services/VMService";
import {
  NotificationApi,
  PaginationRequestModel,
  VmStatusRequestModel,
  UserOfNotification,
  ManagerModel,
} from "shared-components/src/services/openApi/api";
import { NotificationSection, NotificationType, ProjectMessages } from "shared-components/src/definitions/constants";
import store from "@/store";
import { VMApi } from "shared-components/src/services/openApi";
import UrlHelper from "shared-components/src/utils/UrlHelper";
import vmStatus from "shared-components/src/definitions/vmStatus";
import { VmActionLogResponseModel } from "shared-components/src/services/openApi";

import ActivityWatch from "../reports/ActivityWatch.vue";
import Wazuh from "../reports/Wazuh.vue";
import CustomerService from "shared-components/src/services/CustomerService";
import AutoCompleteField from "shared-components/src/components/Fields/AutoCompleteField.vue";

var vmApi = new VMApi();

export interface VMUIModel extends VM {
  IsSelected: boolean | false;
}

export default defineComponent({
  components: { ConfirmDialog, CreateOrEditVM, VdSupport, ChangeStatus, ActionLogList, ActivityWatch, Wazuh },
  async mounted() {
    for (var enumMember in vmStatus) {
      var isValueProperty = Number(enumMember) >= 0;
      if (isValueProperty) {
        this.vmStatuses.push(vmStatus[enumMember]);
      }
    }
    await this.fetchCustomers();
    await this.fetchTeammembers();
    await this.fetchVMs();
    await this.checkAndLoadUrlItems();
  },
  data() {
    return {
      showActivityWatchDialog: false,
      vmStatuses: [] as string[],
      filters: {} as any,
      selectedVmId: "",
      showMaintenanceDialog: false,
      customers: [] as ManagerModel[],
      firstInactiveTeammemberId: "",
      selectedCustomerFilter: "",
      selectedPublicIpFilter: "",
      showWazuhDialog: false,
      tmLoading: true,
      selectedRows: [],
      selectedVmList: [] as VMUIModel[],
      teammembers: [] as TeamMemberSort[] | TeamMemberSummarySort[],
      selectedVM: null as VMUIModel | null,
      selectedVmStatus: "",
      loading: false,
      showDialog: false,
      showStatusPopup: false,
      showActionLogPopup: false,
      vmList: [] as VMUIModel[],
      tableOptions: {} as any,
      page: 1,
      totalItemsCount: 0 as number | undefined,
      numberOfPages: 10,
      selectedTeammemberFilter: "",
      sortProp: "Username",
      sortOrder: "desc",
      headers: [
        { title: "", value: "IsSelected" },
        { title: "Teammember", value: "Teammember", sortable: true },
        { title: "Username", value: "Username", sortable: true },
        { title: "Password", value: "Password", sortable: true },
        { title: "Public IP", value: "PublicIP", sortable: true },
        { title: "Private IP", value: "PrivateIP", sortable: true },
        { title: "Status", value: "Status", sortable: true },
        { title: "Action", value: "action" },
      ],
    };
  },
  computed: {
    isSupport(): boolean {
      return store.getters.userIsSupport ?? false;
    },
  },
  watch: {
    selectedCustomerFilter(newVal: any) {
      if (newVal) {
        this.filters["CustomerId"] = newVal;
      } else {
        delete this.filters["CustomerId"];
      }
    },
    selectedTeammemberFilter(newVal: any) {
      if (newVal) {
        this.filters["OwnerId"] = newVal;
      } else {
        delete this.filters["OwnerId"];
      }
    },
    selectedPublicIpFilter(newVal: any) {
      if (newVal) {
        this.filters["PublicIP"] = newVal;
      } else {
        delete this.filters["PublicIP"];
      }
    },
    tableOptions(newVal) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0].key;
          this.sortOrder = newVal.sortBy[0].order;
        }
        this.fetchVMs();
    },
  },
  methods: {
    findFirstInactiveTeammember(teammemberSortedList: any) {
      var inactiveTeammember = teammemberSortedList.find((c: any) => c.isFirstInactive);
      if (inactiveTeammember) {
        this.firstInactiveTeammemberId = inactiveTeammember.Id;
      }
      console.log(this.firstInactiveTeammemberId);
    },
    async fetchCustomers() {
      try {
        CustomerService.getList(true)
          .then((items: any) => {
            this.customers = items
              .filter((c: any) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim()))
              .map((item: any) => {
                if (item.FirstName && item.FirstName.trim() && item.LastName && item.LastName.trim()) {
                  item.FullName = `${item.FirstName} ${item.LastName}`;
                } else {
                  if (item.FirstName && item.FirstName.trim()) {
                    item.FullName = item.FirstName;
                  } else {
                    item.FullName = item.LastName;
                  }
                }

                return item;
              });
            this.customers.splice(0, 0, { id: "", FullName: "All" } as ManagerModel);
          })
          .catch((err: any) => this.$emit("errorRaised", err));
      } catch (ex) {
        this.$emit("errorRaised", ex);
      }
    },
    close() {
      this.showStatusPopup = false;
      this.showActionLogPopup = false;
      this.showActivityWatchDialog = false;
      this.showWazuhDialog = false;
      this.showDialog = false;
      this.showMaintenanceDialog = false;
      this.selectedVM = null;
      this.selectedVmId = "";
    },
    showActivityWatch(item: any) {
      this.selectedVmId = item.Id;
      this.showActivityWatchDialog = true;
    },
    showVmActionLog(item: any) {
      this.selectedVmId = item.Id;
      this.showActionLogPopup = true;
    },

    getStatusColor(status: string) {
      switch (status) {
        case "Active":
          return "success";
        case "Inactive":
        case "Expired":
        case "Terminated":
          return "error";
        default:
          return "info";
      }
    },
    getClassByStatus(item: any) {
      var className = item.Status === "Expired" || item.Status === "Terminated" ? "disable-row" : "";
      return className;
    },
    openStatusPopup(vmItem: any, status: string) {
      this.selectedVmId = vmItem.Id;
      this.selectedVmStatus = status;
      this.showStatusPopup = true;
    },
    async changeVmStatus(statusModel: VmStatusRequestModel) {
      this.loading = true;
      this.showStatusPopup = false;
      statusModel.Status = this.selectedVmStatus;
      var terminateResult = await vmApi.changeStatus(statusModel);
      var findVm = this.vmList.find((c) => c.Id === statusModel.VmId);
      if (findVm) {
        findVm.Status = this.selectedVmStatus;
      }
      this.selectedVmId = "";
      this.loading = false;
    },
    async checkAndLoadUrlItems() {
      const urlItems = UrlHelper().notification().getUrlHashItems();
      if (urlItems.id && urlItems.action == "view") {
        // view vm
        const vm = await vmApi.getVM(urlItems.id);
        this.editVM(vm);
      }
    },
    removeSelection() {
      this.resetDataTable();
    },
    showMaintenancePopup() {
      this.selectedVmList = this.vmList.filter((c) => c.IsSelected);
      this.showMaintenanceDialog = true;
    },
    createNotification(model: any) {
      var notifStatus = "Pending";
      var requestModel = {
        PushText: "",
        Text: model.Message,
        ShortDesc: model.Title,
        CreationDate: new Date(),
        Section: NotificationSection.Other,
        SmsText: "",
        EmailText: model.Message,
        Users: [] as UserOfNotification[],
        Type: NotificationType.Message,
        SendNow: true,
        Status: notifStatus,
      };

      if (model.Receivers) {
        model.Receivers.forEach((c: any) => {
          var userItem = { UserId: c, Status: notifStatus } as UserOfNotification;
          requestModel.Users.push(userItem);
        });
      }
      new NotificationApi()
        .createNotification(requestModel)
        .then((data: any) => {
          store.dispatch("showSuccessMessage", ProjectMessages.vmNotificationSuccessfully);
          this.cancelRegister();
        })
        .catch((error: any) => {
          store.dispatch("showErrorMessage", ProjectMessages.vmNotificationError);
        });
    },
    selectRow(item: any, row: any) {
      var findRow = this.vmList.find((c: any) => c.Id === row.item.Id);
      if (findRow) {
        findRow.IsSelected = !findRow.IsSelected;
        row.isSelected = findRow.IsSelected;
      }
    },
    getTMName(tmId: any) {
      const tm = (this.teammembers as TeamMemberSummarySort[]).find((tm: any) => tm.Id == tmId);
      if (tm) {
        return tm.FullName;
      } else {
        return "";
      }
    },
    cancelRegister() {
      this.close();
      this.fetchVMs();
    },
    // showActivityWatch(item: any) {
    //   window.location.href = `/vm/activityWatch/${item.Id}`;
    // },
    showWazuhDashboard(item: any) {
      this.selectedVmId = item.Id;
      this.showWazuhDialog = true;
    },
    async fetchTeammembers() {
      try {
        if (AppHelper.IsAzureEnv()) {
          TeamMemberService.getSortedSummaryList()
            .then((items: any) => {
              this.findFirstInactiveTeammember(items);
              this.teammembers = items;
              this.tmLoading = false;
              this.teammembers.splice(0, 0, { Id: "", FullName: "All", isFirstInactive: false } as TeamMemberSummarySort);
            })
            .catch((err: any) => this.$emit("errorRaised", err));
        } else {
          TeamMemberService.getSortedList()
            .then((items: any) => {
              this.findFirstInactiveTeammember(items);
              this.teammembers = items;
              this.tmLoading = false;
              this.teammembers.splice(0, 0, { Id: "", FullName: "All", isFirstInactive: false } as TeamMemberSort);
            })
            .catch((err: any) => this.$emit("errorRaised", err));
        }
      } catch (ex) {
        this.$emit("errorRaised", ex);
      }
    },
    resetDataTable() {
      this.fetchVMs();
    },
    async save(model: VM): Promise<void> {
      try {
        this.loading = true;
        await VMService.save(model);
        store.dispatch("showSuccessMessage", "VM saved successfully");
        await this.fetchVMs();
      } catch (error) {
        store.dispatch("showErrorMessage", "There was an error saving the VM");
        this.loading = false;
      } finally {
        this.showDialog = false;
        this.selectedVM = null;
        this.loading = false;
      }
    },
    clearFilter() {
      this.selectedCustomerFilter = "";
      this.selectedTeammemberFilter = "";
      this.selectedPublicIpFilter = "";
      delete this.filters["CustomerId"];
      delete this.filters["OwnerId"];
      delete this.filters["PublicIP"];
      this.fetchVMs();
    },
    async fetchVMs() {
      try {
        this.loading = true;
        const { page, itemsPerPage } = this.tableOptions;
        const paginationModel = {
          PageNumber: page,
          PageSize: itemsPerPage,
          SortOrder: this.sortOrder,
          SortProp: this.sortProp,
          filters: this.filters,
        } as PaginationRequestModel;

        const result = await vmApi.getVMList(paginationModel);
        if (result.data) {
          this.vmList = [...(result.data.Items as any)] as VMUIModel[];
          this.totalItemsCount = result.data.Total;
        }
      } finally {
        this.loading = false;
      }
    },
    editVM(vmItem: any) {
      this.selectedVM = vmItem;
      this.showDialog = true;
    },
  },
});
</script>
<style lang="scss" scoped>
.disable-row {
  background-color: lightgray;
}
.vm-checkbox {
  height: 50px;
}
.vm-row:hover {
  cursor: pointer;
}
.custom-timesheet-row {
  padding: 10px !important;
  .col {
    overflow-wrap: anywhere;
    text-align: center;
    padding: 0 8px;
  }
  .timesheet-row {
    border: 1px solid #959595;
    border-radius: 10px;
    padding: 10px 0;
    margin: 10px 0;

    .description {
      text-align: left;
      margin: 0 20px;
    }
  }
}
</style>
