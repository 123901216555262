import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "card-title-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_VdSupport = _resolveComponent("VdSupport")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_ViewSupportItem = _resolveComponent("ViewSupportItem")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-title-left" }, "VM Support", -1)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_btn, {
                color: "primary",
                dark: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewSupportModal = true)),
                "prepend-icon": "mdi-plus"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" New Support Notification")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_data_table_server, {
            items: _ctx.supportList,
            headers: _ctx.headers,
            loading: _ctx.loading,
            page: _ctx.paginationModel.page,
            "items-per-page": _ctx.paginationModel.numberOfPages,
            "onUpdate:options": _cache[1] || (_cache[1] = (event) => _ctx.tableOptions = event),
            "items-length": _ctx.paginationModel.totalItemsCount,
            "item-key": "Id"
          }, {
            "item.ShortDesc": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(item.Title), 1)
            ]),
            "item.Sender": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.titleCase(item.Sender ?? "")), 1)
            ]),
            "item.CreatedAt": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(item.CreatedOn)), 1)
            ]),
            "item.LastReplyOn": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(item.LastReplyOn)), 1)
            ]),
            "item.action": _withCtx(({ item }) => [
              _createVNode(_component_v_menu, {
                "offset-y": "",
                location: "bottom",
                "scroll-strategy": "close"
              }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps({
                    size: "small",
                    variant: "text",
                    color: "black"
                  }, props, { icon: "mdi-dots-vertical" }), null, 16)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        onClick: ($event: any) => (_ctx.viewItem(item.Id)),
                        "prepend-icon": "mdi-comment-edit-outline ",
                        title: "View"
                      }, null, 8, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["items", "headers", "loading", "page", "items-per-page", "items-length"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_dialog, {
        width: "600",
        modelValue: _ctx.showNewSupportModal,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showNewSupportModal) = $event)),
        onKeypress: _withKeys(_ctx.closeNewSupportModal, ["esc"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_VdSupport, {
            onCancel: _ctx.closeNewSupportModal,
            onSendRequest: _ctx.createSupportNotification,
            isSupport: true
          }, null, 8, ["onCancel", "onSendRequest"])
        ]),
        _: 1
      }, 8, ["modelValue", "onKeypress"]),
      _createVNode(_component_v_dialog, {
        width: "800",
        modelValue: _ctx.showViewSupportModal,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showViewSupportModal) = $event)),
        onKeypress: _withKeys(_ctx.closeViewSupportModal, ["esc"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ViewSupportItem, {
            onClose: _ctx.closeViewSupportModal,
            supportId: _ctx.selectedSupportId
          }, null, 8, ["onClose", "supportId"])
        ]),
        _: 1
      }, 8, ["modelValue", "onKeypress"])
    ]),
    _: 1
  }))
}