import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_TimeSheetProcessingAddon = _resolveComponent("TimeSheetProcessingAddon")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_PayrollAddon = _resolveComponent("PayrollAddon")!
  const _component_ContractAddon = _resolveComponent("ContractAddon")!
  const _component_VirtualDesktopAddon = _resolveComponent("VirtualDesktopAddon")!
  const _component_OfficeSpaceAddon = _resolveComponent("OfficeSpaceAddon")!
  const _component_LineManagerAddon = _resolveComponent("LineManagerAddon")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ChatBox = _resolveComponent("ChatBox")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_loading_component = _resolveComponent("loading-component")!
  const _component_AddOfficeLocation = _resolveComponent("AddOfficeLocation")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { "no-gutters": "" }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.commitmentModel && _ctx.commitmentModel.id ? "Edit" : "Add") + " Commitment", 1),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  icon: "",
                  dark: "",
                  onClick: _ctx.cancel,
                  fab: "",
                  class: "error",
                  "x-small": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                        _createTextVNode("mdi-close")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_form, {
              ref: "frmCommitment",
              modelValue: _ctx.valid,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.valid) = $event)),
              "lazy-validation": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "4",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DateInputField, {
                          rules: [_ctx.rules.required],
                          label: "Start date",
                          "first-day-of-week": "1",
                          modelValue: _ctx.model.StartDate,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.StartDate) = $event)),
                          "hide-actions": "",
                          placeholder: "Date",
                          "prepend-icon": ""
                        }, null, 8, ["rules", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "4",
                      sm: "6"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DateInputField, {
                          rules: [_ctx.rules.required],
                          label: "End date",
                          "first-day-of-week": "1",
                          modelValue: _ctx.model.EndDate,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.EndDate) = $event)),
                          "hide-actions": "",
                          placeholder: "Date",
                          "prepend-icon": ""
                        }, null, 8, ["rules", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SelectField, {
                          rules: [_ctx.rules.required],
                          label: "Public Holiday Zone",
                          items: _ctx.publicHolidayZones,
                          modelValue: _ctx.model.PublicHolidayZoneId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.PublicHolidayZoneId) = $event)),
                          "item-title": "Name",
                          "item-value": "id",
                          outlined: "",
                          dense: ""
                        }, null, 8, ["rules", "items", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextField, {
                          label: "Hours per week",
                          rules: [_ctx.minMax],
                          type: "number",
                          dense: "",
                          modelValue: _ctx.selectedHoursPerWeekMax,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedHoursPerWeekMax) = $event)),
                          readonly: !_ctx.model.HoursPerWeekRadio,
                          min: "0",
                          outlined: ""
                        }, null, 8, ["rules", "modelValue", "readonly"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_checkbox, {
                          label: "Casual",
                          class: "mt-7",
                          "hide-details": "",
                          modelValue: _ctx.isCasual,
                          "onUpdate:modelValue": [
                            _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isCasual) = $event)),
                            _ctx.casualChange
                          ]
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          rules: [_ctx.rules.required],
                          label: "Project Lead",
                          modelValue: _ctx.model.ProjectLeadId,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.ProjectLeadId) = $event)),
                          items: _ctx.projectLeads,
                          "item-title": "Name",
                          "item-value": "id",
                          outlined: "",
                          dense: "",
                          readonly: _ctx.hasTimesheet
                        }, null, 8, ["rules", "modelValue", "items", "readonly"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          rules: [_ctx.rules.required],
                          label: "Portfolio Manager",
                          modelValue: _ctx.model.PortfolioManagerId,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.PortfolioManagerId) = $event)),
                          items: _ctx.portfolioManagers,
                          "item-title": "Name",
                          "item-value": "id",
                          outlined: "",
                          readonly: _ctx.hasTimesheet,
                          dense: ""
                        }, null, 8, ["rules", "modelValue", "items", "readonly"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          rules: [_ctx.rules.required],
                          label: "Customer",
                          modelValue: _ctx.selectedCustomerId,
                          "onUpdate:modelValue": [
                            _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedCustomerId) = $event)),
                            _ctx.onChangeCustomer
                          ],
                          items: _ctx.customers,
                          "item-title": "Name",
                          "item-value": "id",
                          outlined: "",
                          dense: "",
                          readonly: _ctx.hasTimesheet
                        }, null, 8, ["rules", "modelValue", "items", "readonly", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          loading: _ctx.projectLoading,
                          rules: [_ctx.rules.required],
                          label: "Project",
                          modelValue: _ctx.selectedProjectId,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedProjectId) = $event)),
                          items: _ctx.projects,
                          "item-title": "Name",
                          "item-value": "id",
                          outlined: "",
                          readonly: _ctx.hasTimesheet,
                          dense: ""
                        }, null, 8, ["loading", "rules", "modelValue", "items", "readonly"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AutoCompleteField, {
                          rules: [_ctx.rules.required],
                          label: "Teammember",
                          modelValue: _ctx.selectedTeamMemberId,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedTeamMemberId) = $event)),
                          items: _ctx.teammembers,
                          "item-title": "Name",
                          "item-value": "id",
                          outlined: "",
                          dense: "",
                          readonly: _ctx.hasTimesheet
                        }, null, 8, ["rules", "modelValue", "items", "readonly"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_label, null, {
                          default: _withCtx(() => _cache[28] || (_cache[28] = [
                            _createTextVNode("Location Type")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_radio_group, {
                          inline: "",
                          modelValue: _ctx.model.LocationType,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.LocationType) = $event)),
                          rules: [_ctx.rules.required]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_radio, {
                              label: "Remote",
                              value: "Remote"
                            }),
                            _createVNode(_component_v_radio, {
                              label: "On-Site",
                              value: "OnSite"
                            }),
                            _createVNode(_component_v_radio, {
                              label: "Hybrid",
                              value: "Hybrid"
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue", "rules"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (['Remote', 'OnSite', 'Hybrid'].includes(_ctx.model.LocationType ?? ''))
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              rules: [_ctx.rules.required],
                              label: "Timezone",
                              modelValue: _ctx.model.TimeZone,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.TimeZone) = $event)),
                              items: _ctx.timeZones,
                              outlined: "",
                              dense: ""
                            }, null, 8, ["rules", "modelValue", "items"])
                          ]),
                          _: 1
                        }),
                        (_ctx.model.LocationType != 'Hybrid')
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  rules: [_ctx.rules.required],
                                  label: "Days of Week",
                                  modelValue: _ctx.model.WokingDays,
                                  "onUpdate:modelValue": [
                                    _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.WokingDays) = $event)),
                                    _ctx.DayOfWeekChanged
                                  ],
                                  items: _ctx.workingDays,
                                  multiple: "",
                                  outlined: "",
                                  chips: "",
                                  dense: ""
                                }, null, 8, ["rules", "modelValue", "items", "onUpdate:modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_col, { cols: "2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              rules: [_ctx.rules.required],
                              label: "From",
                              modelValue: _ctx.model.StartWorkClock,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.StartWorkClock) = $event)),
                              items: _ctx.hoursInDay,
                              dense: "",
                              outlined: ""
                            }, null, 8, ["rules", "modelValue", "items"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              rules: [_ctx.rules.required],
                              label: "To",
                              modelValue: _ctx.model.EndWorkClock,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.EndWorkClock) = $event)),
                              items: _ctx.hoursInDay,
                              dense: "",
                              outlined: ""
                            }, null, 8, ["rules", "modelValue", "items"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (['Remote', 'OnSite', 'Hybrid'].includes(_ctx.model.LocationType ?? ''))
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                      default: _withCtx(() => [
                        (_ctx.model.LocationType != 'Remote')
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  outlined: "",
                                  dense: "",
                                  rules: [_ctx.rules.required],
                                  items: _ctx.officeLocations,
                                  "item-title": "Name",
                                  "item-value": "Id",
                                  label: "Office Location",
                                  showRequired: "",
                                  modelValue: _ctx.model.OfficeLocationId,
                                  "onUpdate:modelValue": [
                                    _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.OfficeLocationId) = $event)),
                                    _ctx.checkOfficeSpaceStatus
                                  ]
                                }, {
                                  append: _withCtx(() => [
                                    (_ctx.selectedCustomerId)
                                      ? (_openBlock(), _createBlock(_component_v_btn, {
                                          key: 0,
                                          icon: "",
                                          onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.showAddOfficeLocation = true))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[29] || (_cache[29] = [
                                                _createTextVNode("mdi-plus")
                                              ])),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }, 8, ["rules", "items", "modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.model.LocationType == 'Hybrid')
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 1,
                              cols: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  modelValue: _ctx.model.OfficeDaysPerWeek,
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.OfficeDaysPerWeek) = $event)),
                                  rules: [_ctx.rules.required],
                                  label: "No. Of Office Days Per Week",
                                  items: _ctx.NoOfficeDaysPerWeek,
                                  outlined: "",
                                  dense: ""
                                }, null, 8, ["modelValue", "rules", "items"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.model.LocationType == 'Hybrid')
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 2,
                              cols: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  modelValue: _ctx.model.DaysOfWeekInOffice,
                                  "onUpdate:modelValue": [
                                    _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.DaysOfWeekInOffice) = $event)),
                                    _ctx.DaysInOfficeChanged
                                  ],
                                  rules: [_ctx.rules.required],
                                  label: "Days Of Week In The Office",
                                  items: _ctx.dayInOffice,
                                  multiple: "",
                                  outlined: "",
                                  chips: "",
                                  dense: "",
                                  "return-object": false
                                }, null, 8, ["modelValue", "rules", "items", "onUpdate:modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_col, { cols: "2" })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, { flat: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => _cache[30] || (_cache[30] = [
                                _createTextVNode("Addons")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_expansion_panels, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_expansion_panel, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.timeSheetProcessing.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, {
                                                  class: "d-flex justify-start",
                                                  cols: "4"
                                                }, {
                                                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                                                    _createTextVNode(" Timesheet Processing ")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_TimeSheetProcessingAddon, { timeSheetProcessing: _ctx.timeSheetProcessing }, null, 8, ["timeSheetProcessing"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_expansion_panel, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.payRoll.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, {
                                                  class: "d-flex justify-start",
                                                  cols: "4"
                                                }, {
                                                  default: _withCtx(() => _cache[32] || (_cache[32] = [
                                                    _createTextVNode(" Payroll ")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_PayrollAddon, { payRoll: _ctx.payRoll }, null, 8, ["payRoll"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_expansion_panel, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.contract.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, {
                                                  class: "d-flex justify-start",
                                                  cols: "4"
                                                }, {
                                                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                                                    _createTextVNode(" Contract ")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ContractAddon, { contract: _ctx.contract }, null, 8, ["contract"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_expansion_panel, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.virtualDesktopSpec.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, { cols: "4" }, {
                                                  default: _withCtx(() => _cache[34] || (_cache[34] = [
                                                    _createTextVNode(" Virtual Desktop ")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_VirtualDesktopAddon, {
                                              showEnableCheckBox: true,
                                              virtualDesktopSpec: _ctx.virtualDesktopSpec,
                                              diskValues: _ctx.diskValues,
                                              cpuValues: _ctx.cpuValues,
                                              ramValues: _ctx.ramValues
                                            }, null, 8, ["virtualDesktopSpec", "diskValues", "cpuValues", "ramValues"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    (_ctx.model.LocationType != 'Remote' && _ctx.model.OfficeLocationId == 'OfficeSpace')
                                      ? (_openBlock(), _createBlock(_component_v_expansion_panel, { key: 0 }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_expansion_panel_title, {
                                              class: _normalizeClass({ active: _ctx.officeSpace.Enabled })
                                            }, {
                                              default: _withCtx(({ expanded }) => [
                                                _createVNode(_component_v_row, { "no-gutters": "" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, {
                                                      class: "d-flex justify-start",
                                                      cols: "4"
                                                    }, {
                                                      default: _withCtx(() => _cache[35] || (_cache[35] = [
                                                        _createTextVNode(" Office Space ")
                                                      ])),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }, 8, ["class"]),
                                            _createVNode(_component_v_expansion_panel_text, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_OfficeSpaceAddon, {
                                                  calculateOfficeSpace: _ctx.calculateOfficeSpace,
                                                  officeSpace: _ctx.officeSpace,
                                                  officeSpaces: _ctx.officeSpaces
                                                }, null, 8, ["calculateOfficeSpace", "officeSpace", "officeSpaces"])
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_v_expansion_panel, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, {
                                          class: _normalizeClass({ active: _ctx.lineManagerAddon.Enabled })
                                        }, {
                                          default: _withCtx(({ expanded }) => [
                                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, {
                                                  class: "d-flex justify-start",
                                                  cols: "4"
                                                }, {
                                                  default: _withCtx(() => _cache[36] || (_cache[36] = [
                                                    _createTextVNode(" Line Manager ")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_v_expansion_panel_text, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_LineManagerAddon, {
                                              lineManagerAddon: _ctx.lineManagerAddon,
                                              lineManagers: _ctx.lineManagers
                                            }, null, 8, ["lineManagerAddon", "lineManagers"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_expansion_panel, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, null, {
                                          default: _withCtx(({ expanded }) => [
                                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, {
                                                  class: "d-flex justify-start",
                                                  cols: "4"
                                                }, {
                                                  default: _withCtx(() => _cache[37] || (_cache[37] = [
                                                    _createTextVNode(" Traning Roadmaps ")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_expansion_panel, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, null, {
                                          default: _withCtx(({ expanded }) => [
                                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, {
                                                  class: "d-flex justify-start",
                                                  cols: "4"
                                                }, {
                                                  default: _withCtx(() => _cache[38] || (_cache[38] = [
                                                    _createTextVNode(" Talent Development Manager ")
                                                  ])),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChatBox, {
                      title: "Messages",
                      messages: _ctx.chatMessages,
                      buttons: _ctx.chatButtons,
                      onSendMessage: _ctx.sendMessage,
                      userId: _ctx.getUserId
                    }, null, 8, ["messages", "buttons", "onSendMessage", "userId"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_actions, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { md: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  loading: _ctx.loading,
                  class: "primary_btn mr-5",
                  dark: "",
                  onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.showUpdateStatus(false)))
                }, {
                  default: _withCtx(() => _cache[39] || (_cache[39] = [
                    _createTextVNode("Approve")
                  ])),
                  _: 1
                }, 8, ["loading"]),
                _createVNode(_component_v_btn, {
                  loading: _ctx.loading,
                  class: "secondary_btn mr-5",
                  dark: "",
                  onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.showUpdateStatus(true)))
                }, {
                  default: _withCtx(() => _cache[40] || (_cache[40] = [
                    _createTextVNode("Reject")
                  ])),
                  _: 1
                }, 8, ["loading"]),
                _createVNode(_component_v_btn, {
                  loading: _ctx.loading,
                  class: "primary_btn mr-5",
                  dark: "",
                  onClick: _ctx.save
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[41] || (_cache[41] = [
                        _createTextVNode("mdi-check")
                      ])),
                      _: 1
                    }),
                    _cache[42] || (_cache[42] = _createTextVNode(" Save"))
                  ]),
                  _: 1
                }, 8, ["loading", "onClick"]),
                _createVNode(_component_v_btn, {
                  loading: _ctx.loading,
                  class: "secondary_btn",
                  dark: "",
                  onClick: _ctx.cancel
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[43] || (_cache[43] = [
                        _createTextVNode("mdi-cancel")
                      ])),
                      _: 1
                    }),
                    _cache[44] || (_cache[44] = _createTextVNode(" cancel"))
                  ]),
                  _: 1
                }, 8, ["loading", "onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ], 512), [
        [_vShow, !_ctx.pageLoading]
      ]),
      (_ctx.pageLoading)
        ? (_openBlock(), _createBlock(_component_loading_component, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showAddOfficeLocation,
        "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.showAddOfficeLocation) = $event)),
        persistent: "",
        "max-width": "400px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AddOfficeLocation, {
            onCancel: _cache[22] || (_cache[22] = ($event: any) => (_ctx.showAddOfficeLocation = false)),
            onSaveLocation: _ctx.saveOfficeLocation,
            customerId: _ctx.selectedCustomerId
          }, null, 8, ["onSaveLocation", "customerId"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showUpdateStatusPopup,
        "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.showUpdateStatusPopup) = $event)),
        persistent: "",
        "max-width": "400px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(!_ctx.rejectMode ? "Approve Commitment" : "Reject Commitment"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => _cache[45] || (_cache[45] = [
                      _createElementVNode("span", null, "Are you sure about this action ?", -1)
                    ])),
                    _: 1
                  }),
                  (_ctx.rejectMode)
                    ? (_openBlock(), _createBlock(_component_TextAreaField, {
                        key: 0,
                        rules: [_ctx.rules.required],
                        dense: "",
                        outlined: "",
                        modelValue: _ctx.rejectMessage,
                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.rejectMessage) = $event)),
                        showRequired: "",
                        label: "Reason of Rejecting",
                        placeholder: "Reason of Rejecting"
                      }, null, 8, ["rules", "modelValue"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    md: "12",
                    class: "d-flex justify-end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        loading: _ctx.loading,
                        class: "primary_btn mr-5",
                        k: "",
                        onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.showUpdateStatusPopup = false))
                      }, {
                        default: _withCtx(() => _cache[46] || (_cache[46] = [
                          _createTextVNode("Cancel")
                        ])),
                        _: 1
                      }, 8, ["loading"]),
                      _createVNode(_component_v_btn, {
                        loading: _ctx.loading,
                        class: "secondary_btn",
                        dark: "",
                        onClick: _ctx.updateCommitmentStatus
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(!_ctx.rejectMode ? "Yes! Approve it" : "Yes! Reject it"), 1)
                        ]),
                        _: 1
                      }, 8, ["loading", "onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}