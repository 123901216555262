<template>
  <v-card>
    <v-card-text>
      <v-row class="align-center">
        <v-col class="pb-5">
          <span class="headline">{{ job.Title }}</span>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn icon @click="closeDialog" variant="text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-container>
        <v-row gutter="4">
          <v-col cols="12" class="d-flex align-center">
            <v-avatar class="mr-1" :color="avatarColor" size="12"></v-avatar>
            <span class="task-status"> {{ job.Status }}</span>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Date</span>
            <p>{{ formatDate(job.StartDate) }}</p>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Number of Positions</span>
            <p>{{ job.NoOfPositions }}</p>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Countries</span>
            <p>{{ getNames(countries) }}</p>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Customer</span>
            <p>{{ selectedCutomerName }}</p>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Project Name</span>
            <p>{{ job.ProjectName }}</p>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Skills</span>
            <p>{{ job.Skills?.map((skill) => skill.Text).join(", ") || "" }}</p>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Job Titles</span>
            <span v-for="title in job.JobTitles" :key="title.id">{{ getNames(title.Name) }}</span>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Job Role</span>
            <p>{{ selectedJobRole }}</p>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Hiring Contact</span>
            <p>{{ job.HiringContact }}</p>
          </v-col>

          <v-col cols="3" class="task-section b-r-5 p-16 mb-1">
            <span class="task-title mb-4 d-block">Hiring Manager</span>
            <p>{{ job.HiringmanagerName }}</p>
          </v-col>

          <v-col cols="12" class="task-section b-r-5 p-16">
            <span class="task-title mb-4 d-block">Job Description</span>
            <p>{{ job.JobDescription }}</p>
          </v-col>

          <v-col cols="12" class="task-table">
            <v-data-table class="with-borders" :headers="headerTask" :items="job.Tasks" :hide-default-footer="true">
              <template v-slot:item.task="{ item }">
                {{ truncate(item.Title) }}
              </template>
              <template v-slot:item.taskDate="{ item }">
                {{ formatDate(item.Date) }}
              </template>

              <template v-slot:item.Description="{ item }">
                <v-tooltip location="top" max-width="300px">
                  <template v-slot:activator="{ props }">
                    <span v-bind="props">{{ truncate(item.Description) }}</span>
                  </template>
                  <span>{{ item.Description }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.order="{ item }">
                {{ item.OrderNumber }}
              </template>

              <template v-slot:item.Status="{ item }">
                <v-chip :color="getStatusLabel(item.Status).color">
                  {{ getStatusLabel(item.Status).label }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import {
  PositionTaskModel,
  NotificationApi,
  PositionDetailResponseModel
} from "shared-components/src/services/openApi/api";
import Utils from "shared-components/src/utils/Utils";
import TextAreaField from "shared-components/src/components/Fields/TextAreaField.vue";

var notificationApi = new NotificationApi();
export default defineComponent({
  components: { TextAreaField },
  props: {
    job: {
      type: Object as PropType<PositionDetailResponseModel>,
      required: false,
      default: () => ({}),
    },
    selectedCutomerName: {
      type: String,
      required: true,
    },
    selectedJobRole: {
      type: String,
      required: true,
    },
    locations: {
      type : Array as PropType<any[]>,
      required: true,
    },    
  },
  data() {
    return {
      message: "",
      headerTask: [
        { title: "Task", align: "center", value: "task", sortable: false },
        { title: "Date", align: "center", value: "taskDate", sortable: false },
        { title: "Description", align: "center", value: "Description", sortable: false },
        { title: "Priority", align: "center", value: "order", sortable: false },
        { title: "Status", align: "center", value: "Status", sortable: false },
      ],
    };
  },
  async mounted() {
    await this.getCommentsData();
  },
  computed: {
    avatarColor() {
      return this.job.Status === "Active" ? "green" : "grey";
    },
    countries() {
      var cr = [] as string[];
      
      if (this.job.CountryId && this.locations) {
        this.job.CountryId.forEach((crId) => {
          for (let index = 0; index < this.locations.length; index++) {
            const element = this.locations[index];
           
            if (element.value === crId) {              
              cr.push(element.text ?? "");
            }
          }
        });
      }
      return cr;
    },
  },
  methods: {
    getStatusLabel(status: number | undefined) {
      switch (status) {
        case 0:
          return { label: "New", color: "blue" };
        case 1:
          return { label: "Active", color: "green" };
        case 2:
          return { label: "Resolved", color: "orange" };
        case 3:
          return { label: "Closed", color: "red" };
        case 4:
          return { label: "Archived", color: "gray" };
        default:
          return { label: "Unknown", color: "black" };
      }
    },
    truncate(item: any) {
      if (item && item.length > 50) {
        return item.substring(0, 50) + "...";
      } else {
        return item;
      }
    },
    formatDate(date: any): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "Not set";
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    getNames(name: any): string {
      return Array.isArray(name) ? name.join(", ") : name;
    },
    async getCommentsData() {
      const comment = await notificationApi.getPositionTaskNotifications("positionTaskId");
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";
@import "node_modules/shared-components/assets/style/color.scss";
@import "node_modules/shared-components/assets/style/style.scss";

.task-table {
  border: 2px solid $c_battleship_grey2;
}

.headline {
  font-weight: $bold-l;
  font-size: $size-m;
}

.task-status {
  font-weight: $bold-l;
}

.task-section {
  box-shadow: 0 2px 8px $c_platinum;
  background-color: $c_white_smoke;
}

.task-title {
  font-weight: $bold-l;
  color: $text_color;
}

:deep(.with-borders .v-data-table__wrapper) {
  border-collapse: collapse;
}

:deep(.with-borders th),
:deep(.with-borders td) {
  border-right: 1px solid $c_battleship_grey2;
}

:deep(.with-borders th:last-child),
:deep(.with-borders td:last-child) {
  border-right: none;
}

:deep(.with-borders tr) {
  border-bottom: 1px solid $c_battleship_grey2;
}

:deep(.with-borders tr:last-child) {
  border-bottom: none;
}
</style>
