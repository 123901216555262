import ApiService from "./ApiService";
import { OfficeSpaceModel } from "shared-components/src/services/openApi/api";

export default class OfficeSpaceService {
  public static async GetOfficeSpaces(): Promise<OfficeSpaceModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/officeSpace`, "")
        .then((result) => {
          resolve(result.data as OfficeSpaceModel[]);
        })
        .catch((projectError) => reject(projectError));
    });
  }
}
