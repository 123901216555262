<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            Customers
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="newItem"> <v-icon>mdi-plus</v-icon> New Customer </v-btn>
            <v-dialog v-if="showNewItem" v-model="showNewItem" persistent max-width="600" @keydown.esc="showNewItem = false">
              <new-customer ref="newCustomer" :selectedManager="selectedManager" @cancel="showNewItem = false" @saved="saved"></new-customer>
            </v-dialog>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table :items="managers" :headers="headers" :loading="loading">
            <template v-slot:item.Id="{ item }">
              <strong>{{ item.id }}</strong>
            </template>
            <template v-slot:item.FirstName="{ item }">
              <span>{{ item.FirstName + " " + item.LastName }}</span>
            </template>
            <template v-slot:item.Actions="{ item }">
              <v-menu offset-y location="bottom" scroll-strategy="close">
                <template v-slot:activator="{ props }">
                  <v-btn size="small" variant="text" color="black" v-bind="props" icon="mdi-dots-vertical"> </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="edit(item)" prepend-icon="mdi-pencil-outline" title="Edit" />
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import NewCustomer from "./NewCustomer.vue";
import ManagerModel from "shared-components/src/models/ManagerModel";
import CustomerService from "@/services/CustomerService";

export default defineComponent({
  components: {
    NewCustomer,
  },
  data() {
    return {
      showConfirm: false,
      deleteLoading: false,
      loading: false,
      managers: [] as ManagerModel[],
      showNewItem: false,
      showLoginLinkDialog: false,
      selectedFullName: "",
      selectedManager: {} as ManagerModel,
      confirmModel: {
        title: "",
        text: "",
      },
      headers: [
        { title: "ID", align: "start", value: "id" },
        { title: "Name", value: "FirstName" },
        { title: "Actions", sortable: false, value: "Actions", align: "center" },
      ],
    };
  },
  async mounted() {
    this.loading = true;
    await this.loadItems();
    this.loading = false;
  },
  methods: {
    async loadItems() {
      this.managers = await CustomerService.getList();
    },
    edit(item: any) {
      this.selectedManager = item;
      this.showNewItem = true;
    },
    newItem() {
      this.selectedManager = {} as ManagerModel;
      this.showNewItem = true;
    },
    saved(item: ManagerModel) {
      this.loadItems();
      this.showNewItem = false;
    },
  },
});
</script>
