<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <span class="mt-3">Users list</span>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mt-3 ml-6 mr-3" @click="showNewUser = true"><v-icon>mdi-plus</v-icon> New User</v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6">
            <TextField outlined placeholder="Search" class="mt-5" clearable clear-icon="mdi-close" v-model="searchText"></TextField>
          </v-col>
        </v-row>
        <v-data-table :items="filterdDesserts" :headers="headers" :loading="loading">
          <template v-slot:item.email="{ item }">
            <span class="font-weight-light">{{ item.email }}</span>
          </template>
          <template v-slot:item.uid="{ item }">
            <span class="font-weight-light small">{{ item.id }}</span>
          </template>
          <template v-slot:item.displayName="{ item }">
            <span class="font-weight-light small">{{ item.displayName }}</span>
          </template>
          <template v-slot:item.metadata.creationTime="{ item }">
            <span class="font-weight-light small">{{ formatDate(item.creationDate) }}</span>
          </template>
          <template v-slot:item.roles="{ item }">
            <span class="font-weight-light small">{{ item.roles.join(", ") }}</span>
          </template>
          <template v-slot:item.Actions="{ item }">
            <div>
              <v-btn class="mt-1" variant="text" size="small" title="edit item" @click="edit(item)" icon="mdi-circle-edit-outline"/>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showNewUser" persistent fullscreen @keydown.esc="showNewUser = false">
      <new-user @saved="userSaved" @cancel="showNewUser = false"></new-user>
    </v-dialog>
    <v-dialog v-model="showRole" max-width="300" @keydown.esc="showRole = false">
      <role v-if="showRole" @saved="userSaved" :selectedUser="selectedUser" @cancel="showRole = false"></role>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { UserCreateModel } from "@/services/UserService";
import UserService from "@/services/UserService";
import Utils from "shared-components/src/utils/Utils";
import Vue, { defineComponent } from "vue";
import NewUser from "./NewUser.vue";
import Role from "./Role.vue";
import { UserModel } from "shared-components/src/services/openApi";

export default defineComponent({
  components: {
    NewUser,
    Role,
  },
  data() {
    return {
      searchText: "",
      desserts: [] as UserModel[],
      loading: true,
      showNewUser: false,
      showRole: false,
      selectedUser: {},
      headers: [
        { title: "Email", sortable: true, align: "start", value: "email" },
        { title: "UDID", align: "start", value: "uid" },
        { title: "Display Name", sortable: true, align: "start", value: "displayName" },
        { title: "Created", sortable: true, value: "metadata.creationTime" },
        { title: "Role", sortable: true, value: "role" },
        { title: "Actions", sortable: false, value: "Actions" },
      ],
    };
  },
  methods: {
    edit(item: any) {
      this.selectedUser = item;
      this.showRole = true;
    },
    async userSaved($model: UserCreateModel): Promise<void> {
      this.showNewUser = false;
      this.showRole = false;
      this.loading = true;
      this.desserts = await UserService.getAllUsers();
      this.loading = false;
    },
    formatDate(dt: string): string {
      if (dt) {
        var date = new Date(dt);
        var time = date.getTime();
        if (!isNaN(time)) {
          return Utils.toVsDateFormat(date);
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  async mounted(): Promise<void> {
    this.desserts = await UserService.getAllUsers();
    if (this.desserts && JSON.stringify(this.desserts).includes("Wrong")) {
      this.desserts = [];
    }
    this.loading = false;
  },
  computed: {
    filterdDesserts(): any[] {
      if (this.searchText) {
        let regStr = "";
        this.searchText.split(" ").forEach((part) => (regStr += `.*(${part})+.*`));
        const regSearch = new RegExp(regStr, "ig");
        return this.desserts.filter(
          (item) => (item.email && item.email.search(regSearch) > -1) || (item.displayName && item.displayName.search(regSearch) > -1)
        );
      } else {
        return this.desserts;
      }
    },
  },
});
</script>
<style scoped>
.small {
  font-size: 90%;
}
</style>
