import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "card-title-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ChatBox = _resolveComponent("ChatBox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.loading,
    disabled: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "card-title-left" }, "Support Notification", -1)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_btn, {
                color: "primary",
                dark: "",
                onClick: _ctx.close,
                variant: "text",
                size: "small",
                icon: "mdi-close"
              }, null, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "font-weight-medium" }, "Title", -1)),
                  _createTextVNode(": " + _toDisplayString(_ctx.supportModel?.Title), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "font-weight-medium" }, "Sender", -1)),
                  _createTextVNode(": " + _toDisplayString(_ctx.titleCase(_ctx.supportModel?.Sender)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-weight-medium" }, "Created At", -1)),
                  _createTextVNode(": " + _toDisplayString(_ctx.formatDate(_ctx.supportModel?.CreatedOn)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ChatBox, {
                    title: "Messages",
                    messages: _ctx.chatMessages,
                    showReceivedBy: false,
                    buttons: _ctx.chatButtons,
                    onSendMessage: _ctx.sendSupportNotification,
                    userId: _ctx.getUserId
                  }, null, 8, ["messages", "buttons", "onSendMessage", "userId"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}