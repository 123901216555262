import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "headline" }
const _hoisted_2 = { class: "task-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "align-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { class: "pb-5" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.job.Title), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "d-flex justify-end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    onClick: _ctx.closeDialog,
                    variant: "text"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { gutter: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "d-flex align-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_avatar, {
                        class: "mr-1",
                        color: _ctx.avatarColor,
                        size: "12"
                      }, null, 8, ["color"]),
                      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.job.Status), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Date", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.formatDate(_ctx.job.StartDate)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Number of Positions", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.job.NoOfPositions), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Countries", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.getNames(_ctx.countries)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Customer", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.selectedCutomerName), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[5] || (_cache[5] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Project Name", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.job.ProjectName), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Skills", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.job.Skills?.map((skill) => skill.Text).join(", ") || ""), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Job Titles", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.job.JobTitles, (title) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: title.id
                        }, _toDisplayString(_ctx.getNames(title.Name)), 1))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[8] || (_cache[8] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Job Role", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.selectedJobRole), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[9] || (_cache[9] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Hiring Contact", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.job.HiringContact), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "3",
                    class: "task-section b-r-5 p-16 mb-1"
                  }, {
                    default: _withCtx(() => [
                      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Hiring Manager", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.job.HiringmanagerName), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "task-section b-r-5 p-16"
                  }, {
                    default: _withCtx(() => [
                      _cache[11] || (_cache[11] = _createElementVNode("span", { class: "task-title mb-4 d-block" }, "Job Description", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.job.JobDescription), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "task-table"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_data_table, {
                        class: "with-borders",
                        headers: _ctx.headerTask,
                        items: _ctx.job.Tasks,
                        "hide-default-footer": true
                      }, {
                        "item.task": _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(_ctx.truncate(item.Title)), 1)
                        ]),
                        "item.taskDate": _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(_ctx.formatDate(item.Date)), 1)
                        ]),
                        "item.Description": _withCtx(({ item }) => [
                          _createVNode(_component_v_tooltip, {
                            location: "top",
                            "max-width": "300px"
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), _toDisplayString(_ctx.truncate(item.Description)), 17)
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(item.Description), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        "item.order": _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(item.OrderNumber), 1)
                        ]),
                        "item.Status": _withCtx(({ item }) => [
                          _createVNode(_component_v_chip, {
                            color: _ctx.getStatusLabel(item.Status).color
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getStatusLabel(item.Status).label), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"])
                        ]),
                        _: 1
                      }, 8, ["headers", "items"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}