<template>
  <div>
    <v-card class="overflow-visible">
      <v-card-title>
        PayRoll
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-row>
        <v-col class="filters ml-2" md="3">
          <label class="input-label">Date</label>
          <VueDatePicker v-model="selectedMonth" month-picker auto-apply />
        </v-col>
        <v-col class="filters" md="4">
          <ComboBoxField
            :loading="tmLoading"
            v-model="model.selectedTeammember"
            :items="teammembers"
            :firstInactiveId="firstInactiveTeammemberId"
            item-title="FullName"
            item-value="Id"
            outlined
            dense
            label="Team Member"
          >
          </ComboBoxField>
        </v-col>
        <v-col class="filters mt-11" md="4">
          <v-btn color="primary" @click="loadData()"> <v-icon>mdi-sync</v-icon> Process </v-btn>
          <v-btn color="secondary" class="ml-2" @click="clearFilters()"> <v-icon>mdi-close</v-icon> Clear </v-btn>
        </v-col>
      </v-row>
      <v-card-text> </v-card-text>
      <v-card-text>
        <v-data-table :items="items" :headers="headers" :loading="loading" :custom-sort="customSort" :items-per-page="-1" hide-default-footer>
          <template v-slot:item.Index="{ item }">
            <strong v-if="item.isSum"></strong>
            <strong v-else>{{ item.Index }}</strong>
          </template>
          <template v-slot:item.teamMemberName="{ item }">
            <strong v-if="item.isSum">Total Payments</strong>
            <span v-else>{{ item.teamMemberName }}</span>
          </template>
          <template v-slot:item.fromDate="{ item }">
            <span>{{ getMonthName(item) }}</span>
          </template>

          <template v-slot:item.amount="{ item }">
            <strong v-if="item.isSum">{{ getTotalAudPaymentOfReport(items) }}</strong>
            <span v-else>{{ moneyFormatItem(item) }}</span>
          </template>
          <template v-slot:item.lcAmount="{ item }">
            <strong v-if="item.isSum" v-html="getTotalLCPaymentOfReport(items)"></strong>
            <span v-else>{{ exChangeFormatItem(item) }}</span>
          </template>
          <template v-slot:item.Action="{ item }">
            <span v-if="item.payRun">
              <v-icon color="orange" @click="setReferece(item)">mdi-information</v-icon>
              <span v-html="getPaymentRefAndDate(item)"></span>
            </span>
          </template>
          <template v-slot:item.PayRunInfo="{ item }">
            <span v-if="item.payRun">
              <v-btn class="primary" small @click="showPayRun(item)"> PayRun Details </v-btn>

              <v-icon v-if="!item.payRun.adminApproved && !item.payRun.payments.length" color="orange" @click="editPayRun(item)">mdi-pencil</v-icon>
            </span>
            <span v-else-if="!item.isSum">
              <v-icon color="green" @click="newPayRun(item)">mdi-plus-circle</v-icon>
            </span>
          </template>
        </v-data-table>
      </v-card-text>
      <pay-run-form
        :key="`payRun-${payRunVersion}`"
        :dateArray="[model.FromDate, model.ToDate]"
        :selectedPayRun="selectedPayRun"
        :isEditMode="isEditModeForPayRun"
        :showAddOrEdit="showAddOrEditForPayRun"
        :selectedTeammember="selectedTeammember"
        @setAddEditMode="setAddEditMode"
      />
    </v-card>

    <v-dialog v-if="selectedPayRun" :key="`PDF-Modal-${pdfVersion}`" max-width="800" v-model="showPdf" persistent @keydown.esc="closePdfDialog">
      <PayRunDetail
        v-if="selectedPayRun"
        :key="`PDF-${pdfVersion}`"
        :model="selectedPayRun"
        :isForAdmin="true"
        :payrunAuditTrail="payrunAuditTrail"
        @confirmed="payRunConfirmed"
        @close="closePdfDialog"
      ></PayRunDetail>
    </v-dialog>

    <v-dialog v-if="showSetRefrence" persistent v-model="showSetRefrence" max-width="700" @keydown.esc="showSetRefrence = false">
      <set-refrence
        v-if="showSetRefrence"
        :selectedPayRun="selectedPayRun"
        @close="showSetRefrence = false"
        @confirmed="setPaymentConfirmed"
      ></set-refrence>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { Teammember, TeamMemberSort, TeamMemberSummarySort } from "shared-components/src/models/Teammember";
import Utils from "shared-components/src/utils/Utils";
import ListHelpers from "shared-components/src/utils/ListHelper";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import { PayRoll } from "shared-components/src/viewModels/response/reports/PayRoll";
import PayRunForm from "../payRan/PayRunForm.vue";
import SetRefrence from "../payRan/SetRefrence.vue";
import PayRun from "shared-components/src/models/PayRun";
import PayRunService from "shared-components/src/services/PayRunService";
import ReportService from "shared-components/src/services/ReportService";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import PayRunDetail from "shared-components/src/components/PayRan/PayRunDetail.vue";
import { AuditTrailEvents, Currencies } from "shared-components/src/definitions/constants";
import rules from "shared-components/src/utils/validations";
import AppHelper from "shared-components/src/utils/AppHelper";
import AuditTrail, { RecordType, PayRunAuditTrail } from "shared-components/src/models/AuditTrail";
import AuditTrailService from "shared-components/src/services/AuditTrailService";
import store from "@/store";

interface LCPaymentDic {
  amount: number;
  exchangeCurrencyId: string;
}
export default defineComponent({
  components: { ConfirmDialog, PayRunForm, PayRunDetail, SetRefrence },
  async mounted() {
    this.setDefaultSelectedMonth();
    this.setFromDateAndToDate(this.model.FromDate);
    await this.fetchTeammembers();
  },
  data() {
    return {
      selectedMonth: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      payMonthPickerMenu: false,
      firstInactiveTeammemberId: "",
      tmLoading: true,
      pdfVersion: 0,
      payRunVersion: 0,
      currencies: Currencies,
      exchangeCurrency: Currencies.find((x: any) => x.isDefault),
      mainCurrency: Currencies.find((x: any) => x.isMain),
      showSetRefrence: false,
      showPdf: false,
      payrunAuditTrail: {} as PayRunAuditTrail,
      isEditModeForPayRun: false,
      showAddOrEditForPayRun: false,
      selectedPayRun: null as PayRun | null,
      selectedTeammember: undefined as TeamMemberSort | TeamMemberSummarySort | undefined,
      model: {
        FromDate: new Date() as Date,
        ToDate: new Date() as Date,
        selectedTeammember: null as TeamMemberSort | TeamMemberSummarySort | null,
      },
      loading: false,
      showDialog: false,
      items: undefined as PayRoll[] | undefined,
      teammembers: [] as TeamMemberSort[] | TeamMemberSummarySort[],
      headers: [
        { title: "No.", sortable: false, align: "start", value: "Index", width: "5%" },
        { title: "Team Member", value: "teamMemberName", align: "left", width: "12%" },
        { title: "Month", sortable: false, value: "fromDate", align: "left", width: "15%" },
        { title: "Amount AUD", value: "amount", align: "right", width: "10%" },
        { title: "Payment Amount", value: "lcAmount", align: "right", width: "12%" },
        { title: "Payment Reference", sortable: false, value: "Action", align: "center", width: "28%" },
        { title: "PayRun Info", sortable: false, value: "PayRunInfo", align: "center", width: "18%" },
      ],
      page: 1,
      totalItemsCount: 0,
      numberOfPages: 10,
      sortProp: "ProposedPaymentDate",
      sortOrder: "desc",
      tableOptions: {} as any,
      rules,
      totalPaymentText: "",
    };
  },
  methods: {
    setDefaultSelectedMonth(): void {
      let d = Utils.addMonths(new Date(), -1);
      let m = d.getMonth();
      let y = d.getFullYear();
      this.selectedMonth = {
        month: m,
        year: y,
      };
    },
    snackbarMessage(messageObject: any) {
      if (messageObject.type == "success") {
        store.dispatch("showSuccessMessage", messageObject.message);
      } else {
        store.dispatch("showErrorMessage", messageObject.message);
      }
    },
    customSort(items: any, index: any, isDesc: any) {
      const filteredItems = items.filter((x: any) => !x.isSum);
      ListHelpers().sort(filteredItems, index[0], isDesc[0] ? "asc" : "desc");
      this.setIndex(filteredItems);

      this.setSumRecord(filteredItems);
      return filteredItems;
    },
    payRunConfirmed() {
      this.snackbarMessage({
        message: "PayRun confirmed!",
        type: "success",
      });
      this.closePdfDialog();
    },
    resetItems() {
      this.selectedPayRun = null;
      this.showAddOrEditForPayRun = false;
      this.isEditModeForPayRun = false;
      this.selectedTeammember = undefined;
      this.payRunVersion++;
    },
    setAddEditMode(isEditMode: any, showAddOrEdit: any, loadData: any) {
      this.isEditModeForPayRun = isEditMode;
      this.showAddOrEditForPayRun = showAddOrEdit;
      if (loadData) {
        this.loadData();
      }
    },
    async fetchPayRun(item: any) {
      if (item.payRun) {
        const payRunId = item.payRun.id;

        // set loading
        this.loading = true;
        try {
          this.selectedPayRun = await PayRunService.getById(payRunId);
        } catch (error) {
          this.snackbarMessage({
            message: "There is some error to get Pay Run!",
            type: "error",
          });
        } finally {
          this.loading = false;
        }
      } else {
        this.snackbarMessage({
          message: "Unable to fetch payrun info",
          type: "error",
        });
      }
    },
    getPaymentRefAndDate(item: any) {
      const payRun = item.payRun;
      if (payRun) {
        const refText = PayRunService.getPaymentRefAndDateDescription(payRun.payments, payRun.exchangeCurrency);
        return refText;
      }
      return "";
    },
    async setReferece(item: any) {
      // this.resetItems();
      this.selectedTeammember = (this.teammembers as any).find((x: any) => x.Id == item.teamMember.id);
      await this.fetchPayRun(item);

      if (this.selectedPayRun) {
        this.showSetRefrence = true;
      }
    },
    setPaymentConfirmed(item: PayRun) {
      this.snackbarMessage({
        message: "Reference has been setted",
        type: "success",
      });
      this.showSetRefrence = false;
      this.loadData();
    },
    async showPayRun(item: any) {
      this.pdfVersion++;
      this.resetItems();
      this.selectedTeammember = (this.teammembers as any).find((x: any) => x.Id == item.teamMember.id);
      await this.fetchPayRun(item);
      if (this.selectedPayRun) {
        // get audit trails of payrun
        const auditTrails = await AuditTrailService.getAuditTrailListByTypeAdmin(item.payRun.id, item.teamMember.id);
        if (auditTrails) {
          var tmApproved = auditTrails.find((item: any) => item.Event === AuditTrailEvents.TMApprove);
          var adminApproved = auditTrails.find((item: any) => item.Event === AuditTrailEvents.AdminApprove);
          var paymentRefAdded = auditTrails.find((item: any) => item.Event === AuditTrailEvents.PaymentRefAdd);
          var payrunAuditModel = {} as PayRunAuditTrail;
          if (tmApproved && tmApproved.DateTime) {
            payrunAuditModel.TMApprovedDate = Utils.toVsDateFormat(tmApproved.DateTime);
          }
          if (adminApproved && adminApproved.DateTime) {
            payrunAuditModel.AdminApprovedDate = Utils.toVsDateFormat(adminApproved.DateTime);
          }
          if (paymentRefAdded && paymentRefAdded.DateTime) {
            payrunAuditModel.PaymentRefAddedDate = Utils.toVsDateFormat(paymentRefAdded.DateTime);
          }
          this.payrunAuditTrail = payrunAuditModel;
        }
        this.showPdf = true;
      }
    },
    closePdfDialog() {
      this.showPdf = false;
      this.pdfVersion++;
      this.loadData();
    },
    async editPayRun(item: any) {
      this.resetItems();
      this.selectedTeammember = (this.teammembers as any).find((x: any) => x.Id == item.teamMember.id);
      await this.fetchPayRun(item);
      if (this.selectedPayRun) {
        this.showAddOrEditForPayRun = true;
        this.isEditModeForPayRun = true;
      }
    },
    newPayRun(item: any) {
      this.resetItems();
      this.selectedTeammember = (this.teammembers as any).find((x: any) => x.Id == item.teamMember.id);
      this.showAddOrEditForPayRun = true;
      this.isEditModeForPayRun = false;
    },
    getMonthName(item: any) {
      if (item.fromDate) {
        const date = Utils.vsDateToDatetime(item.fromDate);
        return `${Utils.getMonthName(date)} ${date.getFullYear()}`;
      } else {
        return "";
      }
    },
    moneyFormat(Amount: any) {
      return `${Utils.numberToCurrency(Amount)} ${this.mainCurrencyId}`;
    },
    moneyFormatItem(item: any) {
      if (item.payRun && item.payRun.audTotalPay) {
        return this.moneyFormat(item.payRun.audTotalPay);
      } else {
        return "";
      }
    },
    exChangeFormat(item: LCPaymentDic) {
      return `${Utils.numberToCurrency(item.amount)} ${item.exchangeCurrencyId ? item.exchangeCurrencyId : "TMN"}`;
    },
    exChangeFormatItem(item: any) {
      if (item.payRun && item.payRun.lcTotalPay) {
        return this.exChangeFormat({ amount: item.payRun.lcTotalPay, exchangeCurrencyId: item.payRun.exchangeCurrency } as LCPaymentDic);
      } else {
        return "";
      }
    },
    clearFilters() {
      this.model.FromDate = new Date();
      this.model.ToDate = new Date();
      this.model.selectedTeammember = null;
      this.setDefaultSelectedMonth();
    },
    findFirstInactiveTeammember(teammemberSortedList: any) {
      var inactiveTeammember = teammemberSortedList.find((c: any) => c.isFirstInactive);
      if (inactiveTeammember) {
        this.firstInactiveTeammemberId = inactiveTeammember.Id;
      }
    },
    async fetchTeammembers() {
      try {
        if (AppHelper.IsAzureEnv()) {
          TeamMemberService.getSortedSummaryList()
            .then((items: any) => {
              this.findFirstInactiveTeammember(items);
              this.teammembers = items;
              this.tmLoading = false;
              this.teammembers.splice(0, 0, { Id: "", FullName: "All", isFirstInactive: false } as TeamMemberSummarySort);
            })
            .catch((err: any) => this.$emit("errorRaised", err));
        } else {
          TeamMemberService.getSortedList()
            .then((items: any) => {
              this.findFirstInactiveTeammember(items);
              this.teammembers = items;
              this.tmLoading = false;
              this.teammembers.splice(0, 0, { Id: "", FullName: "All", isFirstInactive: false } as TeamMemberSort);
            })
            .catch((err: any) => this.$emit("errorRaised", err));
        }
      } catch (ex) {
        this.$emit("errorRaised", ex);
      }
    },
    setIndex(items: PayRoll[]) {
      if (items) {
        items.forEach((item, index) => {
          item.Index = index + 1;
        });
      }
    },
    setSumRecord(items: PayRoll[]) {
      // add sum row
      const sumRow = {
        isSum: true,
      };

      items.push(sumRow as any);
    },
    async loadData() {
      try {
        this.loading = true;
        const selectedTeamMemberId = this.model.selectedTeammember ? (this.model.selectedTeammember as Teammember).Id : "";
        const items = await ReportService.getPayrollList(selectedTeamMemberId, this.model.FromDate, this.model.ToDate);

        this.setIndex(items);
        this.setSumRecord(items);

        this.items = items;
      } finally {
        this.loading = false;
      }
    },
    getTotalAudPaymentOfReport(items: PayRoll[] | undefined) {
      if (!items) {
        return "";
      }
      let totalAud = 0;
      if (items) {
        items.forEach((item: any) => {
          if (item.payRun && item.payRun.audTotalPay) {
            totalAud += item.payRun.audTotalPay;
          }
        });
      }

      return this.moneyFormat(totalAud);
    },
    getTotalLCPaymentOfReport(items: PayRoll[] | undefined) {
      if (!items) {
        return "";
      }

      let lcPaymentsArray = [] as LCPaymentDic[];
      items.forEach((item: any) => {
        if (item.payRun && item.payRun.lcTotalPay) {
          const currentLcPayment = lcPaymentsArray.find((x) => x.exchangeCurrencyId === item.payRun.exchangeCurrency);

          if (currentLcPayment) {
            currentLcPayment.amount += item.payRun.lcTotalPay;
          } else {
            const lcPayment = {
              amount: item.payRun.lcTotalPay,
              exchangeCurrencyId: item.payRun.exchangeCurrency,
            } as LCPaymentDic;
            lcPaymentsArray.push(lcPayment);
          }
        }
      });

      let resultText = "";
      lcPaymentsArray.forEach((item) => {
        resultText += `${this.exChangeFormat(item)} <br/>`;
      });

      return resultText;
    },
    setFromDateAndToDate(date: string | Date) {
      // set from date
      if (typeof date === "string") {
        this.model.FromDate = date ? Utils.vsDateToDatetime(`${date}-01`) : Utils.vsDateToDatetime(new Date().toDateString());
      } else {
        this.model.FromDate = Utils.firstDayOfMonth(date);
      }

      // set to date
      this.model.ToDate = Utils.lastOfTime(Utils.lastDayOfMonth(this.model.FromDate));
    },
  },
  computed: {
    exchangeCurrencyId(): string {
      return this.exchangeCurrency ? this.exchangeCurrency.id : "";
    },
    mainCurrencyId(): string {
      return this.mainCurrency ? this.mainCurrency.id : "";
    },
    isExchangeCurrencyIdIsSameAsMainCurrency(): boolean {
      return this.exchangeCurrencyId == this.mainCurrencyId;
    },
    selectedDate: {
      get(): Date {
        return this.model.FromDate;
      },
      set(date: string): void {
        this.setFromDateAndToDate(date);
      },
    },
    selectedDateText: {
      get(): string {
        return Utils.getMonthName(this.model.ToDate);
      },
      set(date: string): void {},
    },
  },
  watch: {
    selectedMonth(newValue) {
      if (newValue) {
        this.setFromDateAndToDate(`${newValue.year}-${newValue.month + 1}`);
      }
    },
    selectedTeammember() {
      this.loadData();
    },
    selectedStatus() {
      this.loadData();
    },
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        this.loadData();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.custom-table-row {
  background-color: red;
}
.custom-timesheet-row {
  padding: 10px !important;
  .col {
    overflow-wrap: anywhere;
    text-align: center;
    padding: 0 8px;
  }
  .timesheet-row {
    border: 1px solid #959595;
    border-radius: 10px;
    padding: 10px 0;
    margin: 10px 0;

    .description {
      text-align: left;
      margin: 0 20px;
    }
  }
}
</style>
