<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            Line Managers
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="newItem"> <v-icon>mdi-plus</v-icon> New Line Manager </v-btn>
            <v-dialog v-if="showNewItem" v-model="showNewItem" persistent max-width="600" @keydown.esc="showNewItem = false">
              <new-line-manager
                ref="newLineManager"
                :selectedLineManager="selectedLineManager"
                @cancel="showNewItem = false"
                @saved="saved"
              ></new-line-manager>
            </v-dialog>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table :items="lineManagers" :headers="headers" :loading="loading">
            <template v-slot:item.id="{ item }">
              <strong>{{ item.id }}</strong>
            </template>
            <template v-slot:item.FirstName="{ item }">
              <span>{{ item.FirstName }}</span>
            </template>
            <template v-slot:item.LastName="{ item }">
              <span>{{ item.LastName }}</span>
            </template>
            <template v-slot:item.Email="{ item }">
              {{ item.Email }}
            </template>
            <template v-slot:item.IsActive="{ item }">
              <v-checkbox hide-details readonly v-model="item.IsActive" />
            </template>
            <template v-slot:item.Actions="{ item }">
              <v-btn class="secondary mt-1" variant="text" size="small" title="Edit" @click="edit(item)" icon="mdi-circle-edit-outline" />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import LineManagerService from "shared-components/src/services/LineManagerService";
import NewLineManager from "./NewLineManager.vue";
import LineManager from "shared-components/src/models/LineManager";

export default defineComponent({
  components: {
    NewLineManager,
  },
  data() {
    return {
      showConfirm: false,
      deleteLoading: false,
      loading: false,
      lineManagers: [] as LineManager[],
      showNewItem: false,
      selectedLineManager: {} as LineManager,
      confirmModel: {
        title: "",
        text: "",
      },
      headers: [
        { title: "ID", align: "start", value: "id" },
        { title: "First Name", value: "FirstName" },
        { title: "Last Name", value: "LastName" },
        { title: "Email", value: "Email" },
        { title: "Is Active", value: "IsActive" },
        { title: "Actions", sortable: false, value: "Actions" },
      ],
    };
  },
  async mounted() {
    this.loading = true;
    this.lineManagers = await LineManagerService.getList(true);
    this.loading = false;
  },
  methods: {
    edit(item: any) {
      this.selectedLineManager = item;
      this.showNewItem = true;
    },
    newItem() {
      this.selectedLineManager = {} as LineManager;
      this.showNewItem = true;
    },
    saved(item: LineManager) {
      const idx = this.lineManagers.findIndex((x) => x.id === item.id);
      if (idx !== -1) {
        this.lineManagers[idx].FirstName = item.FirstName;
        this.lineManagers[idx].LastName = item.LastName;
        this.lineManagers[idx].Email = item.Email;
        this.lineManagers[idx].IsActive = item.IsActive;
        this.selectedLineManager = {} as LineManager;
      } else {
        this.lineManagers.push(item);
      }
      this.showNewItem = false;
    },
  },
});
</script>
