import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withKeys as _withKeys } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_DateRangeInputField = _resolveComponent("DateRangeInputField")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_Timesheet = _resolveComponent("Timesheet")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _cache[8] || (_cache[8] = _createElementVNode("span", null, " HR Commitments Report", -1)),
              _createVNode(_component_v_divider, {
                class: "mx-4",
                inset: "",
                vertical: ""
              }),
              _createVNode(_component_v_spacer)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "align-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "3",
                sm: "4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AutoCompleteField, {
                    items: _ctx.customers,
                    "item-title": "text",
                    "item-value": "id",
                    modelValue: _ctx.selectedCustomer,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCustomer) = $event)),
                    label: "Customer"
                  }, null, 8, ["items", "modelValue"])
                ]),
                _: 1
              }),
              (_ctx.selectedCustomer)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    cols: "12",
                    md: "3",
                    sm: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        items: _ctx.commitments,
                        "item-title": "text",
                        "item-value": "id",
                        modelValue: _ctx.selectedCommitment,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCommitment) = $event)),
                        label: "Commitment"
                      }, null, 8, ["items", "modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.selectedCommitment)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    cols: "12",
                    md: "2",
                    sm: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        items: _ctx.teammembers,
                        "item-title": "text",
                        "item-value": "id",
                        modelValue: _ctx.selectedTeammember,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedTeammember) = $event)),
                        label: "Teammember"
                      }, null, 8, ["items", "modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "2",
                sm: "2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DateRangeInputField, {
                    modelValue: _ctx.selectedDateRange,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedDateRange) = $event)),
                    label: "Date Range",
                    "first-day-of-week": "1",
                    "hide-actions": "",
                    placeholder: "Select Date Range",
                    "prepend-icon": ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "2",
                sm: "2",
                class: "mt-3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_menu, {
                    "offset-y": "",
                    left: "",
                    attach: ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        small: "",
                        icon: "",
                        light: ""
                      }, props), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "",
                            cir: ""
                          }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode(" mdi-menu-down ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_text, { class: "pa-6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list, { flat: "" }, {
                                default: _withCtx(() => [
                                  _cache[10] || (_cache[10] = _createTextVNode(" Options ")),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dateRanges, (item) => {
                                    return (_openBlock(), _createBlock(_component_v_list_item, {
                                      key: item,
                                      value: item,
                                      title: item,
                                      onClick: ($event: any) => (_ctx.selectedRange(item))
                                    }, null, 8, ["value", "title", "onClick"]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "mb-3" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                md: "2",
                sm: "4",
                cols: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "primary",
                    "x-large": "",
                    onClick: _ctx.createReport
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("mdi-sync")
                        ])),
                        _: 1
                      }),
                      _cache[12] || (_cache[12] = _createTextVNode(" Process "))
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                md: "2",
                sm: "4",
                cols: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "secondary",
                    "x-large": "",
                    onClick: _ctx.clearFilters
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      }),
                      _cache[14] || (_cache[14] = _createTextVNode(" Clear Filters "))
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_data_table, {
            items: _ctx.filteredHrCommitmentsReport,
            headers: _ctx.headers,
            loading: _ctx.isLoading
          }, {
            "item.TeammemberName": _withCtx(({ item }) => [
              _createElementVNode("strong", null, _toDisplayString(item.TeammemberName), 1)
            ]),
            "item.ProjectName": _withCtx(({ item }) => [
              _createElementVNode("span", null, _toDisplayString(item.ProjectName), 1)
            ]),
            "item.CustomerName": _withCtx(({ item }) => [
              _createElementVNode("span", null, _toDisplayString(item.CustomerName), 1)
            ]),
            "item.CommitmentName": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(item.CommitmentName), 1)
            ]),
            "item.CommitmentType": _withCtx(({ item }) => [
              (item.CommitmentMin || item.CommitmentMax)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", null, [
                      _cache[15] || (_cache[15] = _createElementVNode("strong", null, "Min: ", -1)),
                      _createTextVNode(_toDisplayString(item.CommitmentMin), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Max: ", -1)),
                      _createTextVNode(_toDisplayString(item.CommitmentMax), 1)
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[17] || (_cache[17] = [
                    _createElementVNode("strong", null, "Casual", -1)
                  ])))
            ]),
            "item.TotalHours": _withCtx(({ item }) => [
              _createElementVNode("span", {
                style: _normalizeStyle([item.Adjustment && item.Adjustment != 0 ? { color: 'blue' } : { color: 'black' }])
              }, _toDisplayString(item.TotalHours + item.Adjustment), 5)
            ]),
            "item.Actions": _withCtx(({ item }) => [
              _createElementVNode("div", null, [
                _createVNode(_component_v_btn, {
                  class: "secondary mt-1",
                  "x-small": "",
                  title: "Edit",
                  onClick: ($event: any) => (_ctx.timesheet(item))
                }, {
                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createTextVNode("Show Timesheet")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ])
            ]),
            _: 1
          }, 8, ["items", "headers", "loading"])
        ]),
        _: 1
      }),
      (_ctx.showTimesheet)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 0,
            modelValue: _ctx.showTimesheet,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showTimesheet) = $event)),
            persistent: "",
            "max-width": "1100",
            onKeydown: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.showTimesheet = false), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Timesheet, {
                useAsModal: true,
                useForCustomer: true,
                importedTeammemberId: _ctx.selectedHrCommtimentReport.TeammemberId,
                importedCommitmentId: _ctx.selectedHrCommtimentReport.CommitmentId,
                onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showTimesheet = false)),
                onCancelRating: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showTimesheet = false))
              }, null, 8, ["importedTeammemberId", "importedCommitmentId"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}