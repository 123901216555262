<template>
  <div>
    <v-card>
      <v-card-title>
        Public Holidays
        <v-divider class="mx-4" inset vertical></v-divider>
        <span class="errorMessage" v-if="zoneMessage">{{ zoneMessage }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-divider></v-divider>
        <v-row>
          <v-col class="mt-2" cols="12" md="4" xs="12" sm="6">
            <v-row>
              <v-col class="zoneTxt" cols="12" md="8" xs="12" sm="6">
                <TextField v-model="zoneTitle" dense outlined class="mt-md-3" label="Zone" type="text" />
              </v-col>
              <v-col class="zoneBut mt-7" cols="12" md="4" xs="12" sm="6">
                <v-btn class="mt-md-3 addZoneBut" color="primary" @click="addZone()"> Add </v-btn>
              </v-col>
            </v-row>
            <v-col cols="12" md="11" xs="12" sm="6" class="zoneMenu">
              <h2>Zones</h2>
              <TextField dense outlined class="mt-md-3 mb-0" v-model="selectedHolidayName" label="Search" type="text" />

              <div class="zoneList">
                <ul>
                  <li v-for="item in tempPublicHolidays" :key="item.Id">
                    <a
                      v-bind:style="[item.IsClicked ? { background: 'rgb(226 226 226)' } : { 'background-color': 'none' }]"
                      @click="selectedHoliday = item"
                    >
                      {{ item.Title }}
                    </a>
                  </li>
                </ul>
              </div>
            </v-col>
          </v-col>
          <v-col class="zoneDetail mt-2 pl-0" cols="12" md="8" xs="12" sm="6">
            <v-row v-if="selectedHoliday">
              <v-col cols="12" md="7" xs="12" sm="6">
                <TextField v-model="holidayTitle" dense outlined class="mt-md-3" label="Holiday" type="text" />
              </v-col>
              <v-col cols="12" md="5" xs="12" sm="6">
                <v-row>
                  <v-col cols="12" md="8" xs="12" sm="6">
                    <DateInputField label="Date" first-day-of-week="1" v-model="holidayDate" hide-actions placeholder="Date" prepend-icon="" />
                  </v-col>
                  <v-col cols="12" md="4" xs="12" sm="6">
                    <v-btn class="mt-9 addHolidayButton" color="primary" @click="addDates()"> Add </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-col class="pb-0" v-if="selectedHoliday && selectedHoliday.Id" cols="12" md="12" xs="12" sm="6">
              <v-row>
                <v-col class="pb-0 pl-0" cols="12" md="10" xs="12" sm="6">
                  <h1 class="selectedZoneTitle">{{ selectedHoliday.Title }}</h1>
                </v-col>
                <v-col class="pb-0 deleteZone" cols="12" md="2" xs="12" sm="6">
                  <v-btn class="error" title="Delete" @click="removeZone()">Delete</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="holidayDates && holidayDates.length > 0" class="holidaysList mb-5 pt-0 pl-0" cols="12" md="12" xs="12" sm="6">
              <v-data-table class="holidayItem holidayColumnTitle mt-1 mr-0" :items="holidayDates" :headers="headers" :loading="loading">
                <template v-slot:item.Title="{ item }">
                  <span>{{ item.Title }}</span>
                </template>
                <template v-slot:item.Date="{ item }">
                  <span>{{ item.Date }}</span>
                </template>
                <template v-slot:item.Actions="{ item }">
                  <div>
                    <v-btn class="error removeDateButton" fab x-small title="Delete" @click="removeDate(item)"><v-icon>mdi-delete</v-icon></v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-col>
            <v-col class="holidayMessage mt-3" v-else>
              <h1 v-if="selectedHoliday">{{ selectedHoliday.Title }} doesn't have any Dates</h1>
              <h1 v-else>Please select a Zone</h1>
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showConfirm" max-width="500" @keydown.esc="showConfirm = false">
      <confirm-dialog :message="confirmModel" @confirm="confirmRemove" @cancel="cancelRemove"></confirm-dialog>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import PublicHolidayService from "shared-components/src/services/PublicHolidayService";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import { PublicHoliday, HolidayDate } from "shared-components/src/models/PublicHoliday";
import Utils from "shared-components/src/utils/Utils";

export interface PublicHolidayModel extends PublicHoliday {
  IsClicked: boolean | false;
}

export default defineComponent({
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      headers: [
        { title: "Holiday", align: "start", value: "Title", sortable: false },
        { title: "Date", value: "Date" },
        { title: "Actions", value: "Actions", sortable: false },
      ],
      publicHolidays: [] as PublicHolidayModel[],
      tempPublicHolidays: [] as PublicHolidayModel[],
      holidayDates: [] as HolidayDate[],
      loading: true,
      selectedHoliday: null as PublicHolidayModel | null,
      selectedDate: null as HolidayDate | null,
      confirmModel: {
        title: "",
        text: "",
      },
      selectedHolidayName: "",
      showConfirm: false,
      zoneTitle: "",
      holidayTitle: "",
      holidayDate: null as Date | null,
      zoneMessage: "",
      holidayDatePickerMenu: false,
    };
  },
  async mounted() {
    await this.fetchPublicHolidays();
  },
  methods: {
    async fetchPublicHolidays(): Promise<void> {
      this.loading = true;
      this.publicHolidays = (await PublicHolidayService.getList()) as PublicHolidayModel[];
      this.tempPublicHolidays = this.publicHolidays;
      this.loading = false;
    },
    async addZone() {
      const trimedZone = this.zoneTitle.trim();
      if (!trimedZone) {
        this.zoneMessage = "Zone is required";
      } else {
        this.loading = true;
        if (!this.publicHolidays.map((c) => c.Title).includes(trimedZone)) {
          const zone = { Title: trimedZone, Dates: [] as HolidayDate[] } as PublicHoliday;
          await PublicHolidayService.set(zone as PublicHoliday);
          this.tempPublicHolidays = this.publicHolidays;
          this.tempPublicHolidays.push(zone as PublicHolidayModel);
          this.zoneMessage = "";
          this.selectedHolidayName = "";
          this.zoneTitle = "";
        } else {
          this.zoneMessage = this.zoneTitle + " already exist";
        }
        this.loading = false;
      }
    },
    getDate(date: any) {
      return date;
    },
    async addDates() {
      const trimedHolidayTitle = this.holidayTitle.trim();
      if (this.selectedHoliday && this.selectedHoliday.Id) {
        if (!trimedHolidayTitle || !this.holidayDate) {
          if (!trimedHolidayTitle) {
            this.zoneMessage = "Holiday title is reqiured";
          } else {
            this.zoneMessage = "Holiday Date is reqiured";
          }
        } else {
          const exist = this.holidayDates.some((c) => this.holidayDate && this.getDate(c.Date) === Utils.toVsDateFormat(this.holidayDate));
          if (!exist) {
            const holidayModel = {
              Id: this.selectedHoliday.Id,
              Title: this.selectedHoliday.Title,
              Dates: this.selectedHoliday.Dates,
            } as PublicHoliday;
            const newDate = { Title: trimedHolidayTitle, Date: this.holidayDate } as HolidayDate;
            if (holidayModel.Dates) {
              holidayModel.Dates.push(newDate);
              await PublicHolidayService.set(holidayModel as PublicHoliday);
              const convertedDate = newDate.Date ? Utils.toVsDateFormat(newDate.Date) : newDate.Date;
              const uiNewDate = { Title: newDate.Title, Date: convertedDate } as HolidayDate;
              this.holidayDates.push(uiNewDate);
              this.zoneMessage = "";
            }
          } else {
            this.zoneMessage = "the Date already exist";
          }
        }
      } else {
        this.zoneMessage = "Please select a Zone";
      }
    },
    async removeDate(date: any) {
      this.selectedDate = date;
      this.confirmModel.title = `Remove Holiday(${date.Title})`;
      this.confirmModel.text = "Are you sure?";
      this.showConfirm = true;
    },
    cancelRemove(): void {
      this.showConfirm = false;
    },

    removeZone() {
      if (this.selectedHoliday) {
        this.confirmModel.title = `Remove Zone(${this.selectedHoliday.Title})`;
        this.confirmModel.text = "Are you sure?";
        this.showConfirm = true;
      }
    },
    async confirmRemove(): Promise<void> {
      this.showConfirm = false;
      if (this.selectedDate) {
        const index = this.holidayDates.findIndex(
          (c) => this.selectedDate && c.Title === this.selectedDate.Title && c.Date === this.selectedDate.Date
        );
        if (this.selectedHoliday && this.selectedHoliday.Dates) {
          this.holidayDates.splice(index, 1);
          this.selectedHoliday.Dates.splice(index, 1);
          const dates = [] as HolidayDate[];
          this.holidayDates.forEach((c) => {
            const dateModel = { Title: c.Title, Date: Utils.vsDateToDatetime(this.getDate(c.Date)) } as HolidayDate;
            dates.push(dateModel);
          });
          const holidayModel = { Id: this.selectedHoliday.Id, Title: this.selectedHoliday.Title, Dates: dates } as PublicHoliday;
          await PublicHolidayService.set(holidayModel);
          this.selectedDate = null;
        }
      } else if (this.selectedHoliday) {
        const idx = this.publicHolidays.findIndex((x) => this.selectedHoliday && x.Id === this.selectedHoliday.Id);
        await PublicHolidayService.remove(this.selectedHoliday.Id);
        this.publicHolidays.splice(idx, 1);
        const tempIdx = this.tempPublicHolidays.findIndex((x) => this.selectedHoliday && x.Id === this.selectedHoliday.Id);
        if (tempIdx !== -1) {
          this.tempPublicHolidays.splice(tempIdx, 1);
        }
        this.selectedHoliday = { Title: "", IsClicked: false, Dates: [], Id: "" } as PublicHolidayModel;
      }
    },
    convertDate(date: any) {
      const dateTime = Utils.timestampToDate(date);
      const dateFromat = Utils.toVsDateFormat(dateTime);
      if (!dateFromat.includes("NaN")) {
        return dateFromat;
      } else {
        const convertedDate = Utils.toVsDateFormat(date);
        return convertedDate;
      }
    },
  },
  computed: {
    selectedHolidayDate: {
      get(): string {
        return this.holidayDate ? Utils.toVsDateFormat(this.holidayDate) : "";
      },
      set(date: string | null): void {
        if (date && date.length === 10) {
          this.holidayDate = Utils.vsDateToDatetime(date || "");
        }
      },
    },
  },
  watch: {
    selectedHoliday(newVal: any) {
      this.publicHolidays.forEach((item: any) => {
        item.IsClicked = false;
      });
      if (this.selectedHoliday) {
        this.selectedHoliday.IsClicked = true;
      }
      if (newVal.Dates && newVal.Dates.length > 0) {
        this.holidayDates = newVal.Dates.sort((a: any, b: any) => a.Date - b.Date).map((c: any) => {
          return { Title: c.Title, Date: this.convertDate(c.Date) };
        });
      } else {
        this.holidayDates = [];
      }
    },
    selectedHolidayName(newVal: any) {
      if (newVal) {
        this.tempPublicHolidays = this.publicHolidays.filter((c: any) => c.Title.toLowerCase().includes(newVal.toLowerCase()));
      } else {
        this.tempPublicHolidays = this.publicHolidays;
      }
    },
  },
});
</script>

<style scoped>
.zoneMenu {
  height: 60vh;
  overflow-y: scroll;
  border-radius: 5px;
  border: 1px solid rgb(167, 167, 167);
  margin-bottom: 10px;
}
.zoneMenu h2 {
  text-align: center;
  border-bottom: 1px solid #dadada;
  height: 37px;
}
.zoneList ul {
  padding-left: 0px;
}
.zoneList ul li {
  height: 40px;
  list-style-type: none;
  line-height: 40px;
}
.zoneList ul li a {
  padding-left: 20px;
  display: block;
  color: black;
}
.zoneList ul li a:hover {
  background: rgb(226 226 226);
}
.holidayItem {
  border-bottom: 1px solid rgb(94, 94, 94);
}
.removeHoliday h3 a {
  color: red;
}
.holidayColumnTitle {
  border-bottom: 3px solid rgb(94, 94, 94);
}
.holidayMessage h1 {
  text-align: center;
  line-height: 30px;
}
.errorMessage {
  color: red;
}
.selectedZoneTitle {
  line-height: 38px;
}
.removeDateButton {
  height: 25px;
  width: 25px;
}
.removeDateButton i {
  font-size: 15px !important;
}
@media screen and (max-width: 539px) {
  .addZoneBut {
    width: 100%;
    margin-top: 0px !important;
  }
  .zoneBut {
    padding-top: 0px;
    margin-bottom: 25px;
  }
  .zoneTxt {
    padding-bottom: 0;
  }
  .addHolidayButton {
    width: 100%;
  }
  .deleteZone {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 15px;
  }
  .deleteZone button {
    width: 100%;
  }
  .holidaysList {
    padding-right: 0px;
  }
  .zoneDetail {
    padding-left: 10px !important;
  }
}
</style>
