import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_new_commitment = _resolveComponent("new-commitment")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_clone_commitment = _resolveComponent("clone-commitment")!
  const _component_loading_component = _resolveComponent("loading-component")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_v_col, { md: "12" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { "no-gutters": "" }, {
                    default: _withCtx(() => [
                      _cache[16] || (_cache[16] = _createTextVNode(" Commitment List ")),
                      _createVNode(_component_v_divider, {
                        class: "mx-4",
                        inset: "",
                        vertical: ""
                      }),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        dark: "",
                        onClick: _ctx.newItem
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode("mdi-plus")
                            ])),
                            _: 1
                          }),
                          _cache[15] || (_cache[15] = _createTextVNode(" New "))
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      (_ctx.showNewItem)
                        ? (_openBlock(), _createBlock(_component_v_dialog, {
                            key: 0,
                            modelValue: _ctx.showNewItem,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showNewItem) = $event)),
                            persistent: "",
                            fullscreen: "",
                            onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.showNewItem = false), ["esc"]))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_new_commitment, {
                                ref: "newCommitment",
                                commitments: _ctx.desserts,
                                commitmentModel: _ctx.editItem,
                                onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewItem = false)),
                                onSaved: _ctx.commitmentSaved
                              }, null, 8, ["commitments", "commitmentModel", "onSaved"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "align-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { md: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            items: _ctx.customers,
                            "item-title": "FullName",
                            "item-value": "id",
                            modelValue: _ctx.selectedCustomer,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedCustomer) = $event)),
                            label: "Customer",
                            outlined: "",
                            dense: "",
                            "hide-details": ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            "hide-details": "",
                            items: _ctx.projects,
                            "item-title": "Name",
                            "item-value": "id",
                            modelValue: _ctx.selectedProject,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedProject) = $event)),
                            label: "Project",
                            outlined: "",
                            dense: ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            items: _ctx.teammembers,
                            "item-title": "FullName",
                            "item-value": "Id",
                            modelValue: _ctx.selectedTeammember,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedTeammember) = $event)),
                            label: "Team Member",
                            outlined: "",
                            dense: "",
                            "hide-details": ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            items: _ctx.linemanagers,
                            "item-title": "FirstName",
                            "item-value": "id",
                            modelValue: _ctx.selectedLineManager,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedLineManager) = $event)),
                            label: "Line Manager",
                            outlined: "",
                            dense: "",
                            "hide-details": ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_switch, {
                            "hide-details": "",
                            label: "Active",
                            class: "mt-8",
                            modelValue: _ctx.isActive,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isActive) = $event)),
                            color: "success"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { md: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            color: "secondary",
                            class: "mt-6",
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.clearFilters()))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[17] || (_cache[17] = [
                                  _createTextVNode("mdi-close")
                                ])),
                                _: 1
                              }),
                              _cache[18] || (_cache[18] = _createTextVNode(" Clear "))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_data_table, {
                    items: _ctx.tempDesserts,
                    headers: _ctx.headers,
                    loading: _ctx.loading,
                    "custom-sort": _ctx.customSort
                  }, {
                    "item.Name": _withCtx(({ item }) => [
                      _createElementVNode("strong", null, _toDisplayString(item.Name), 1)
                    ]),
                    "item.Status": _withCtx(({ item }) => [
                      (_ctx.checkDate(item) == 0)
                        ? (_openBlock(), _createBlock(_component_v_chip, {
                            key: 0,
                            class: "ma-2",
                            color: "success"
                          }, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createTextVNode(" Active ")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.checkDate(item) == 2)
                        ? (_openBlock(), _createBlock(_component_v_chip, {
                            key: 1,
                            class: "ma-2",
                            color: "error"
                          }, {
                            default: _withCtx(() => _cache[20] || (_cache[20] = [
                              _createTextVNode(" Inactive ")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.checkDate(item) == 1)
                        ? (_openBlock(), _createBlock(_component_v_chip, {
                            key: 2,
                            class: "ma-2",
                            color: "error"
                          }, {
                            default: _withCtx(() => _cache[21] || (_cache[21] = [
                              _createTextVNode(" Expired ")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.checkDate(item) == 3)
                        ? (_openBlock(), _createBlock(_component_v_chip, {
                            key: 3,
                            class: "ma-2",
                            color: "yellow"
                          }, {
                            default: _withCtx(() => _cache[22] || (_cache[22] = [
                              _createTextVNode(" Upcoming ")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    "item.TeamMembers": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatTeammembers(item)), 1)
                    ]),
                    "item.StartDate": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatDate(item.StartDate)), 1)
                    ]),
                    "item.EndDate": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatDate(item.EndDate)), 1)
                    ]),
                    "item.Projects": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatProjects(item)), 1)
                    ]),
                    "item.Customers": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatCustomers(item)), 1)
                    ]),
                    "item.Type": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.HoursPerWeekRadio ? "Hours Per week" : "Casual") + " ", 1),
                      (item.HoursPerWeekRadio)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _cache[23] || (_cache[23] = _createElementVNode("strong", null, "Min: ", -1)),
                            _createTextVNode(_toDisplayString(item.HoursPerWeekMin), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (item.HoursPerWeekRadio)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _cache[24] || (_cache[24] = _createElementVNode("strong", null, "Max: ", -1)),
                            _createTextVNode(_toDisplayString(item.HoursPerWeekMax), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    "item.Actions": _withCtx(({ item }) => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_v_btn, {
                          class: "secondary mt-2",
                          fab: "",
                          size: "small",
                          title: "Edit",
                          variant: "text",
                          onClick: ($event: any) => (_ctx.edit(item)),
                          icon: "mdi-circle-edit-outline"
                        }, null, 8, ["onClick"])
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_v_btn, {
                          class: "error mt-1 mb-1",
                          fab: "",
                          size: "small",
                          title: "Delete",
                          variant: "text",
                          onClick: ($event: any) => (_ctx.remove(item)),
                          icon: "mdi-delete"
                        }, null, 8, ["onClick"])
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_v_btn, {
                          class: "accent mb-2",
                          fab: "",
                          size: "small",
                          title: "Clone",
                          variant: "text",
                          onClick: ($event: any) => (_ctx.clone(item)),
                          icon: "mdi-content-copy"
                        }, null, 8, ["onClick"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["items", "headers", "loading", "custom-sort"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512), [
        [_vShow, !_ctx.pageLoading]
      ]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showConfirm,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showConfirm) = $event)),
        "max-width": "500",
        onKeydown: _cache[10] || (_cache[10] = _withKeys(($event: any) => (_ctx.showConfirm = false), ["esc"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_confirm_dialog, {
            message: _ctx.confirmModel,
            onConfirm: _ctx.confirmRemove,
            onCancel: _ctx.cancelRemove
          }, null, 8, ["message", "onConfirm", "onCancel"])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_ctx.ShowClone)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 0,
            modelValue: _ctx.ShowClone,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.ShowClone) = $event)),
            persistent: "",
            "max-width": "500",
            onKeydown: _cache[13] || (_cache[13] = _withKeys(($event: any) => (_ctx.ShowClone = false), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_clone_commitment, {
                ref: "cloneCommitment",
                commitment: _ctx.cloningItem,
                onCancel: _cache[11] || (_cache[11] = ($event: any) => (_ctx.ShowClone = false)),
                onClone: _ctx.cloneCommitment
              }, null, 8, ["commitment", "onClone"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.pageLoading)
        ? (_openBlock(), _createBlock(_component_loading_component, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}