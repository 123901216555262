<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="12">
          <v-card>
            <v-card-title class="title">
              <span class="headline">Task List</span>
              <v-btn color="primary" @click="openNewTaskDialog">
                <v-icon left>mdi-plus</v-icon>
                New Task
              </v-btn>
            </v-card-title>

            <v-container>
              <v-row>
                <v-col cols="12" :md="isHiringmanager ? 4 : 3" xs="12" sm="6">
                  <AutoCompleteField
                    item-title="Title"
                    item-value="Id"
                    v-model="namePositionFilter"
                    :items="namePosition"
                    dense
                    outlined
                    label="Job Opening"
                    placeholder="Job Opening"
                    type="text"
                  ></AutoCompleteField>
                </v-col>
                <v-col cols="12" :md="isHiringmanager ? 4 : 3" xs="12" sm="6">
                  <TextField v-model="taskTitleFilter" dense outlined label="Task Title" placeholder="Task Title" type="text"></TextField>
                </v-col>
                <v-col cols="12" :md="isHiringmanager ? 4 : 3" xs="12" sm="6">
                  <DateInputField
                    @click:clear="onDateClear"
                    clearable
                    first-day-of-week="1"
                    v-model="datefilter"
                    hide-actions
                    label="Task Date"
                    placeholder="Date"
                    prepend-icon=""
                  />
                </v-col>
                <v-col v-if="!isHiringmanager" cols="12" :md="isHiringmanager ? 4 : 3" xs="12" sm="6">
                  <AutoCompleteField
                    :items="hiringManagerList"
                    item-title="fullName"
                    item-value="id"
                    v-model="hiringManagerFilter"
                    dense
                    outlined
                    label="Hiring Manager"
                    placeholder="Hiring Manager"
                    type="text"
                  ></AutoCompleteField>
                </v-col>
              </v-row>

              <v-col cols="12" class="filter">
                <v-btn @click="clearFilter">
                  <v-icon left>mdi-close</v-icon>
                  clear Filter
                </v-btn>
                <v-btn color="primary" @click="filteredTasks">
                  <v-icon left>mdi-check</v-icon>
                  Filter
                </v-btn>
              </v-col>
            </v-container>
            <v-data-table-server
              :loading="dataLoading"
              :headers="headerTask"
              :items="tableTasks"
              class="elevation-1 pr-5 pl-5"
              :page="page"
              :items-per-page="numberOfPages"
              :items-length="totalItemsCount"
              @update:options="(event: any) => tableOptions = event"
            >
              <template v-slot:item.Status="{ item }">
                <v-chip :color="getStatusColor(item.Status)" dark>
                  {{ item.Status }}
                </v-chip>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-menu offset-y location="left">
                  <template v-slot:activator="{ props }">
                    <v-btn x-small icon v-bind="props" variant="text">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list density="compact">
                    <v-list-item
                      :disabled="actionItem.text === 'Edit' && isHiringmanager"
                      v-for="(actionItem, index) in actions"
                      :key="index"
                      @click="actionItem.action(item)"
                      color="primary"
                    >
                      <template v-slot:prepend>
                        <v-icon :icon="actionItem.icon" class="mr-1" color="blue" small></v-icon>
                      </template>
                      <v-list-item-title v-text="actionItem.text" class="text-left"></v-list-item-title>
                    </v-list-item>

                    <v-menu offset-y location="left">
                      <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props">
                          <template v-slot:prepend>
                            <v-icon color="blue" small>mdi-content-save-cog</v-icon>
                          </template>
                          <v-list-item-title>Status</v-list-item-title>
                        </v-list-item>
                      </template>

                      <v-list>
                        <v-list-item @click="changeStatusTask(item, 'New')">
                          <v-list-item-title>New</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="changeStatusTask(item, 'Active')">
                          <v-list-item-title>Active</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="changeStatusTask(item, 'Resolved')">
                          <v-list-item-title>Resolved</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="changeStatusTask(item, 'Closed')">
                          <v-list-item-title>Closed</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="changeStatusTask(item, 'Archived')">
                          <v-list-item-title>Archived</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table-server>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog max-width="800px" v-model="taskDialog" v-if="taskDialog" transition="dialog-bottom-transition">
        <TaskDetail :selectedRow="selectedRow" :isHiringmanager="isHiringmanager" :item="selectedTask" @close-dialog="closeDialog"></TaskDetail>
      </v-dialog>
      <v-dialog v-model="newTaskDialog" v-if="newTaskDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <NewTask
          :isHiringmanager="isHiringmanager"
          :is-editing="isEditing"
          :task="selectedTask"
          @TaskSaved="TaskSaved"
          @close-task-dialog="closeNewTaskDialog"
        ></NewTask>
      </v-dialog>
    </v-container>
  </div>
</template>
<script lang="ts">
import TaskDetail from "./TaskDetail.vue";
import TextField from "shared-components/src/components/Fields/TextField.vue";
import NewTask from "./NewTask.vue";
import {
  TaskTemplateApi,
  EntitySummaryModel,
  UserApi,
  PositionTaskResponseModel,
  PositionApi,
  NotificationApi,
  PositionTaskDetailResponseModel,
  PaginationRequestModel,
  TaskTemplateModel,
} from "shared-components/src/services/openApi/api";
import store from "@/store";
import { defineComponent } from "vue";
import Utils from "@/utils/Utils";

const positionApi = new PositionApi();
const notificationApi = new NotificationApi();
const userApi = new UserApi();
const taskApi = new TaskTemplateApi();
export default defineComponent({
  components: { TaskDetail, TextField, NewTask },
  data() {
    return {
      rules: {
        required: (value: any) => !!value || "Required.",
      },
      headerTask: [
        { title: "Job Opening Name", align: "start", value: "PositionTitle", sortable: false },
        { title: "Hiring Manager", align: "start", value: "HiringManagerName", sortable: false },
        { title: "Task Title", align: "center", value: "TaskTitle", sortable: false },
        { title: "Task Date", align: "center", value: "Date", sortable: false },
        { title: "Task Status", align: "center", value: "Status", sortable: false },
        { title: "Actions", align: "end", value: "actions", sortable: false },
      ],
      actions: [
        { text: "Edit", icon: "mdi-pencil", action: this.editTask },
        { text: "View", icon: "mdi-tooltip-text", action: this.viewTask },
      ],
      taskDialog: false,
      newTaskDialog: false,
      tableTasks: [] as PositionTaskResponseModel[],
      selectedTask: {} as PositionTaskDetailResponseModel,
      selectedRow: {} as PositionTaskDetailResponseModel,
      namePositionFilter: "",
      taskTitleFilter: "",
      startDateFilter: "",
      startDateMenu: false,
      isEditing: false,
      selectedDate: null as Date | null,
      datefilter: null as Date | null,
      hiringManagerFilter: "",
      tableOptions: {} as any,
      sortOrder: "desc",
      sortProp: "Status",
      numberOfPages: 10,
      totalItemsCount: 0 as number | undefined,
      page: 1,
      hiringManagerList: [] as any,
      namePosition: [] as EntitySummaryModel[],
      taskList: [] as TaskTemplateModel[],
      filters: {} as any,
      dataLoading: true,
    };
  },
  async mounted() {
    await this.getHiringManagerList();
    await this.getTabelTaskData();
    await this.getPositonSummeryList();
    await this.getTaskList();
  },
  watch: {
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0];
        }
        if (newVal.sortDesc && newVal.sortDesc.length) {
          this.sortOrder = newVal.sortDesc[0] ? "desc" : "Status";
        }
      }
      this.getTabelTaskData();
    },
    namePositionFilter(newVal) {
      if (newVal) {
        this.filters["PositionId"] = newVal;
      } else {
        delete this.filters["PositionId"];
      }
    },
    taskTitleFilter(newVal) {
      if (newVal) {
        this.filters["TaskTemplateName"] = newVal;
      } else {
        delete this.filters["TaskTemplateName"];
      }
    },
    datefilter(newVal) {
      var stringDate = Utils.toVsDateFormat(newVal);
      if (stringDate) {
        this.filters["Date"] = stringDate;
      } else {
        delete this.filters["Date"];
      }
    },
    hiringManagerFilter(newVal) {
      if (newVal) {
        this.filters["Position.HiringManagerId"] = newVal;
      } else {
        delete this.filters["Position.HiringManagerId"];
      }
    },
  },
  computed: {
    isHiringmanager(): boolean {
      return store.getters.userIsHiringmanager ?? false;
    },
    formattedTableTasks(): PositionTaskResponseModel[] {
      return this.tableTasks.map((task) => ({
        ...task,
        Date: this.formatDate(task.Date),
      }));
    },
  },
  methods: {
    getStatusColor(status: string | undefined) {
      if (status === "New") {
        return "blue";
      } else if (status === "Active") {
        return "green";
      } else if (status === "Resolved") {
        return "orange";
      } else if (status === "Closed") {
        return "red";
      } else if (status === "Archived") {
        return "gray";
      } else {
        return "default";
      }
    },
    async changeStatusTask(item: any, status: any) {
      this.dataLoading = true;
      try {
        await positionApi.changePositionTaskStatus(item.Id, status);
        item.Status = status;
        this.dataLoading = false;
      } catch (err) {
        console.error(err);
      }
    },
    async getTaskList() {
      if (!this.isHiringmanager) {
        this.taskList = (await taskApi.geTaskTemplateList()).data.Items as TaskTemplateModel[];
      }
    },
    async getPositonSummeryList() {
      await positionApi.getPositionSummaryList().then((data) => {
        this.namePosition = data.data;
      });
    },
    async getHiringManagerList() {
      if (!this.isHiringmanager) {
        try {
          const response = await userApi.getUsersByRole("hiringmanager");
          this.hiringManagerList = response.data.map((manager: any) => ({
            id: manager.id,
            fullName: manager.name,
          }));
        } catch (error) {
          console.error("Error fetching hiring managers:", error);
        }
      }
    },
    onDateClear() {
      this.datefilter = null;
    },
    async filteredTasks() {
      if (!this.isHiringmanager) {
        try {
          const { page, itemsPerPage } = this.tableOptions;
          var stringDate = "";
          if (this.datefilter) {
            stringDate = Utils.toVsDateFormat(this.datefilter);
          }

          var paginationModel = {
            PageNumber: page,
            PageSize: itemsPerPage,
            SortOrder: this.sortOrder,
            SortProp: this.sortProp,
            Filters: this.filters,
          } as PaginationRequestModel;
          const response = await positionApi.gePositionTaskList(paginationModel);
          this.dataLoading = false;
          if (response.data && response.data.Items) {
            response.data.Items.forEach((c: PositionTaskResponseModel) => (c.Date = c.Date ? Utils.toVsDateFormat(new Date(c.Date)) : ""));
            this.tableTasks = response.data.Items as PositionTaskResponseModel[];
          }
        } catch (error) {
          console.error("Error fetching task data:", error);
        }
      }
    },
    formatDate(date?: string): string {
      if (!date) return "";
      return date.split("T")[0];
    },
    async viewTask(item: PositionTaskResponseModel) {
      this.dataLoading = true;
      const result = await positionApi.gePositionTaskDetail(item.Id as string);
      result.data.Date = result.data.Date ? Utils.toVsDateFormat(new Date(result.data.Date)) : "";
      this.dataLoading = false;
      this.selectedRow = item;
      this.selectedTask = result.data;
      this.taskDialog = true;
      this.dataLoading = false;
    },
    closeDialog() {
      this.taskDialog = false;
    },
    closeNewTaskDialog() {
      this.newTaskDialog = false;
      this.isEditing = false;
    },
    openNewTaskDialog() {
      this.selectedTask = {};
      this.isEditing = false;
      this.newTaskDialog = true;
    },
    async getTabelTaskData() {
      try {
        const { page, itemsPerPage } = this.tableOptions;
        const model = {
          PageNumber: page,
          PageSize: itemsPerPage,
          SortOrder: this.sortOrder,
          SortProp: this.sortProp,
          filters: {},
        } as PaginationRequestModel;
        const response = await positionApi.gePositionTaskList(model);
        this.totalItemsCount = response.data.Total;
        this.dataLoading = false;
        if (response.data && response.data.Items) {
          response.data.Items.forEach((c: PositionTaskResponseModel) => (c.Date = c.Date ? Utils.toVsDateFormat(new Date(c.Date)) : ""));
        }
        this.tableTasks = response.data.Items as PositionTaskResponseModel[];
      } catch (error) {
        console.error("Error fetching task data:", error);
      }
    },
    TaskSaved(response: PositionTaskResponseModel) {
      if (response && response.Id) {
        response.Date = response.Date ? Utils.toVsDateFormat(new Date(response.Date)) : "";
        const finededIndex = this.tableTasks.findIndex((item) => item.Id == response.Id);
        if (finededIndex == -1) {
          this.tableTasks.unshift(response);
        } else {
          this.tableTasks.splice(finededIndex, 1, response);
        }
      } else {
        console.error("Invalid response:", response);
      }

      this.closeNewTaskDialog();
    },
    async editTask(item: PositionTaskResponseModel) {
      this.dataLoading = true;
      const result = await positionApi.gePositionTaskDetail(item.Id as string);
      this.selectedTask = result.data;
      this.dataLoading = true;
      this.newTaskDialog = true;
      this.isEditing = true;
    },
    clearFilter() {
      this.namePositionFilter = "";
      this.taskTitleFilter = "";
      this.datefilter = null;
      this.hiringManagerFilter = "";

      this.getTabelTaskData();
    },
  },
});
</script>
<style scoped>
.headline {
  font-weight: bold;
  padding-left: 8px;
}
.title {
  display: flex;
  justify-content: space-between;
}
.filter {
  gap: 8px;
  display: flex;
  justify-content: flex-end;
}
</style>
