import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_CurrencyTextField = _resolveComponent("CurrencyTextField")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _cache[9] || (_cache[9] = _createElementVNode("span", null, " Set Payment Reference/Date", -1)),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                icon: "",
                dark: "",
                onClick: _ctx.close,
                fab: "",
                class: "error",
                "x-small": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("mdi-close")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, {
                ref: "frmPayment",
                modelValue: _ctx.valid,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valid) = $event)),
                "lazy-validation": "",
                class: "ref-form"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        md: "5",
                        sm: "4",
                        cols: "6"
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createElementVNode("b", null, "Payrun Date:", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        md: "7",
                        sm: "8",
                        cols: "6"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.PayRunFromDate)) + " - " + _toDisplayString(_ctx.formatDate(_ctx.PayRunToDate)), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fillAmount()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        md: "5",
                        sm: "4",
                        cols: "6"
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createElementVNode("b", null, "Total Amount: ", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        md: "7",
                        sm: "8",
                        cols: "6"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.formatNumber(_ctx.PayRunLCTotalPay)) + " " + _toDisplayString(_ctx.PayRunExchangeCurrency), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { md: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_TextField, {
                    rules: [_ctx.rules.required],
                    label: "Payment Refrence",
                    modelValue: _ctx.model.PaymentRefNo,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.PaymentRefNo) = $event)),
                    outlined: "",
                    dense: ""
                  }, null, 8, ["rules", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { md: "3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_menu, {
                    modelValue: _ctx.paymentDatePickerMenu,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.paymentDatePickerMenu) = $event)),
                    "close-on-content-click": true,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "290px"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_TextField, _mergeProps({
                        rules: [_ctx.rules.required],
                        label: "Payment Date",
                        modelValue: _ctx.formatedPaymentDate,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formatedPaymentDate) = $event)),
                        readonly: "",
                        outlined: ""
                      }, props, { dense: "" }), null, 16, ["rules", "modelValue"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_date_picker, {
                        "first-day-of-week": "1",
                        ref: "picker",
                        modelValue: _ctx.model.PaymentDate,
                        "onUpdate:modelValue": [
                          _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.PaymentDate) = $event)),
                          _cache[5] || (_cache[5] = ($event: any) => (_ctx.paymentDatePickerMenu = false))
                        ]
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { md: "3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_CurrencyTextField, {
                    rules: [_ctx.rules.required, _ctx.rules.currency, _ctx.rules.notZero],
                    dense: "",
                    outlined: "",
                    label: `Amount ${_ctx.PayRunExchangeCurrency}`,
                    modelValue: _ctx.model.LCPaymentAmount,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.LCPaymentAmount) = $event)),
                    readonly: _ctx.isReadOnly
                  }, null, 8, ["rules", "label", "modelValue", "readonly"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                md: "2",
                sm: "2",
                cols: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    fab: "",
                    "x-small": "",
                    color: "primary",
                    class: "mt-9",
                    onClick: _ctx.addPayment
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode("mdi-plus")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.PayRunPayments)
            ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_data_table, {
                    headers: _ctx.paymentListHeaders,
                    items: _ctx.PayRunPayments,
                    "item-key": "id",
                    class: "elevation-1 custom-table-row"
                  }, {
                    "item.PaymentDate": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatDate(item.PaymentDate)), 1)
                    ]),
                    "item.PaymentRefNo": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.PaymentRefNo), 1)
                    ]),
                    "item.LCTotalPay": _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatNumber(item.LCTotalPay)) + " " + _toDisplayString(item.ExchangeCurrency ? item.ExchangeCurrency : "TMN"), 1)
                    ]),
                    "item.Action": _withCtx(({ item }) => [
                      _createVNode(_component_v_btn, {
                        fab: "",
                        "x-small": "",
                        color: "error",
                        class: "mt-2",
                        dark: "",
                        onClick: ($event: any) => (_ctx.removePayment(item))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createTextVNode("mdi-delete")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 1
                  }, 8, ["headers", "items"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { md: "6" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    color: "primary",
                    onClick: _ctx.setPaymentInfo,
                    loading: _ctx.loading
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createTextVNode("mdi-check")
                        ])),
                        _: 1
                      }),
                      _cache[15] || (_cache[15] = _createTextVNode(" Set "))
                    ]),
                    _: 1
                  }, 8, ["onClick", "loading"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                md: "6",
                class: "text-right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    color: "error",
                    onClick: _ctx.close
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1
                      }),
                      _cache[17] || (_cache[17] = _createTextVNode(" Cancel "))
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}