import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-weight-bold" }
const _hoisted_2 = { class: "font-weight-bold" }
const _hoisted_3 = { class: "font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, { class: "overflow-visible" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _cache[4] || (_cache[4] = _createTextVNode(" Expense Analysis ")),
            _createVNode(_component_v_divider, {
              class: "mx-4",
              inset: "",
              vertical: ""
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_v_col, {
                  md: "3",
                  sm: "6",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _cache[5] || (_cache[5] = _createElementVNode("label", { class: "input-label" }, "Date", -1)),
                    _createVNode(_component_VueDatePicker, {
                      modelValue: _ctx.selectedMonth,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMonth) = $event)),
                      "month-picker": "",
                      "min-date": _ctx.getMinForecastDate(),
                      "auto-apply": ""
                    }, null, 8, ["modelValue", "min-date"])
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.reportType === 'Forecast']
                ]),
                _withDirectives(_createVNode(_component_v_col, {
                  md: "3",
                  sm: "6",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _cache[6] || (_cache[6] = _createElementVNode("label", { class: "input-label" }, "Date", -1)),
                    _createVNode(_component_VueDatePicker, {
                      teleport: "",
                      modelValue: _ctx.selectedDateRange,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedDateRange) = $event)),
                      "max-date": new Date(),
                      range: "",
                      "auto-apply": "",
                      "enable-time-picker": false
                    }, null, 8, ["modelValue", "max-date"])
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.reportType !== 'Forecast']
                ]),
                _createVNode(_component_v_col, {
                  md: "3",
                  sm: "6",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ComboBoxField, {
                      dense: "",
                      modelValue: _ctx.selectedTeammember,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedTeammember) = $event)),
                      loading: _ctx.tmLoading,
                      disabled: _ctx.tmLoading,
                      items: _ctx.teammemberSortList,
                      firstInactiveId: _ctx.firstInactiveTeammemberId,
                      "item-title": "FullName",
                      "item-value": "Id",
                      outlined: "",
                      label: "Teammember"
                    }, null, 8, ["modelValue", "loading", "disabled", "items", "firstInactiveId"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  md: "2",
                  sm: "6",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_radio_group, {
                      class: "mt-0",
                      modelValue: _ctx.reportType,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.reportType) = $event)),
                      row: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_radio, {
                          label: "Actual",
                          value: "Actual"
                        }),
                        _createVNode(_component_v_radio, {
                          label: "Forecast",
                          value: "Forecast"
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  md: "2",
                  sm: "4",
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      class: "accent action-button",
                      loading: _ctx.loading,
                      onClick: _ctx.createReport
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("mdi-sync")
                          ])),
                          _: 1
                        }),
                        _cache[8] || (_cache[8] = _createTextVNode(" Process "))
                      ]),
                      _: 1
                    }, 8, ["loading", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "text-center" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredExpenseAnalysis.TotalPaymentUnit, (item) => {
                      return (_openBlock(), _createBlock(_component_v_btn, {
                        key: item.Unit,
                        class: "ma-3"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.formatNumber(item.Value)) + " " + _toDisplayString(item.Unit), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredExpenseAnalysis.PayrunDetailByTeammember, (item) => {
                  return (_openBlock(), _createBlock(_component_v_col, {
                    md: "4",
                    sm: "6",
                    cols: "12",
                    key: item.TeammemberId
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, { class: "teammember-payrun-card" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTeammemberName(item.TeammemberId)), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.PayrunDetailByMonth, (monthly) => {
                                return (_openBlock(), _createBlock(_component_v_col, {
                                  class: "pl-0 pr-0",
                                  key: monthly.MonthNumber
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, {
                                          md: "4",
                                          sm: "4",
                                          cols: "12",
                                          class: "pt-6"
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.getMonthNameByNumber(monthly.MonthNumber)), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_col, {
                                          md: "4",
                                          sm: "4",
                                          cols: "12",
                                          class: "pl-0 pr-0"
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(monthly.BasePaymentUnit, (basePayment) => {
                                              return (_openBlock(), _createBlock(_component_v_col, { key: basePayment }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.formatNumber(basePayment.Value)) + " ", 1),
                                                  _createElementVNode("span", _hoisted_2, _toDisplayString(basePayment.Unit), 1)
                                                ]),
                                                _: 2
                                              }, 1024))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_col, {
                                          md: "4",
                                          sm: "4",
                                          cols: "12",
                                          class: "pl-0 pr-0"
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(monthly.ConvertedPaymentUnit, (convertedPaymentUnit) => {
                                              return (_openBlock(), _createBlock(_component_v_col, { key: convertedPaymentUnit }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.formatNumber(convertedPaymentUnit.Value)) + " ", 1),
                                                  _createElementVNode("span", _hoisted_3, _toDisplayString(convertedPaymentUnit.Unit), 1)
                                                ]),
                                                _: 2
                                              }, 1024))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}