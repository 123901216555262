<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <span> HR Commitments Report</span>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row class="align-center">
        <v-col cols="12" md="3" sm="4">
          <AutoCompleteField :items="customers" item-title="text" item-value="id" v-model="selectedCustomer" label="Customer" />
        </v-col>
        <v-col v-if="selectedCustomer" cols="12" md="3" sm="4">
          <AutoCompleteField :items="commitments" item-title="text" item-value="id" v-model="selectedCommitment" label="Commitment" />
        </v-col>
        <v-col v-if="selectedCommitment" cols="12" md="2" sm="2">
          <AutoCompleteField :items="teammembers" item-title="text" item-value="id" v-model="selectedTeammember" label="Teammember" />
        </v-col>
        <v-col cols="12" md="2" sm="2">
          <DateRangeInputField
            v-model="selectedDateRange"
            label="Date Range"
            first-day-of-week="1"
            hide-actions
            placeholder="Select Date Range"
            prepend-icon=""
          />
        </v-col>
        <v-col cols="12" md="2" sm="2" class="mt-3">
          <v-menu offset-y left attach>
            <template v-slot:activator="{ props }">
              <v-btn small icon light v-bind="props">
                <v-icon icon cir> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text class="pa-6">
                <v-list flat>
                  Options
                  <v-list-item v-for="item in dateRanges" :key="item" :value="item" :title="item" @click="selectedRange(item)" />
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col md="2" sm="4" cols="12">
          <v-btn class="primary" x-large @click="createReport"> <v-icon>mdi-sync</v-icon> Process </v-btn>
        </v-col>
        <v-col md="2" sm="4" cols="12">
          <v-btn class="secondary" x-large @click="clearFilters"> <v-icon>mdi-close</v-icon> Clear Filters </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text>
      <v-data-table :items="filteredHrCommitmentsReport" :headers="headers" :loading="isLoading">
        <template v-slot:item.TeammemberName="{ item }">
          <strong>{{ item.TeammemberName }}</strong>
        </template>
        <template v-slot:item.ProjectName="{ item }">
          <span>{{ item.ProjectName }}</span>
        </template>
        <template v-slot:item.CustomerName="{ item }">
          <span>{{ item.CustomerName }}</span>
        </template>
        <template v-slot:item.CommitmentName="{ item }">
          {{ item.CommitmentName }}
        </template>
        <template v-slot:item.CommitmentType="{ item }">
          <div v-if="item.CommitmentMin || item.CommitmentMax">
            <div><strong>Min: </strong>{{ item.CommitmentMin }}</div>
            <div><strong>Max: </strong>{{ item.CommitmentMax }}</div>
          </div>
          <div v-else>
            <strong>Casual</strong>
          </div>
        </template>
        <template v-slot:item.TotalHours="{ item }">
          <span v-bind:style="[item.Adjustment && item.Adjustment != 0 ? { color: 'blue' } : { color: 'black' }]">
            {{ item.TotalHours + item.Adjustment }}
          </span>
        </template>
        <template v-slot:item.Actions="{ item }">
          <div>
            <v-btn class="secondary mt-1" x-small title="Edit" @click="timesheet(item)">Show Timesheet</v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-if="showTimesheet" v-model="showTimesheet" persistent max-width="1100" @keydown.esc="showTimesheet = false">
      <Timesheet
        :useAsModal="true"
        :useForCustomer="true"
        :importedTeammemberId="selectedHrCommtimentReport.TeammemberId"
        :importedCommitmentId="selectedHrCommtimentReport.CommitmentId"
        @closeModal="showTimesheet = false"
        @cancelRating="showTimesheet = false"
      />
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import CustomerService from "shared-components/src/services/CustomerService";
import CommitmentService from "shared-components/src/services/CommitmentService";
import HRCommitmentReportService from "../../services/HRCommitmentReportService";
import HRCommitmentReport from "shared-components/src/models/HRCommitmentReport";
import Timesheet from "../timesheets/Timesheet.vue";
import Utils from "shared-components/src/utils/Utils";
import Commitment from "shared-components/src/models/Commitment";
import ManagerModel from "shared-components/src/models/ManagerModel";
import moment from "moment";

export default defineComponent({
  components: {
    Timesheet,
  },
  data() {
    return {
      customersArrays: [] as ManagerModel[],
      customers: [] as Array<{ id: string; text: string }>,
      commitmentsArrays: [] as Commitment[],
      commitments: [] as Array<{ id: string; text: string }>,
      teammembers: [] as Array<{ id: string; text: string }>,
      hrCommitmentsReport: [] as HRCommitmentReport[],
      filteredHrCommitmentsReport: [] as HRCommitmentReport[],
      selectedHrCommtimentReport: {} as HRCommitmentReport,
      selectedCustomer: "",
      selectedCustomerTeammembers: [] as string[],
      selectedCommitment: "",
      selectedTeammember: "",
      dateRanges: ["This Week", "Last Week", "Last Month", "This Month", "Last Year", "This Year"],
      selectedDateRange: [],
      datePickerMenu: false,
      isLoading: false,
      showTimesheet: false,
      headers: [
        { title: "Teammember", align: "start", value: "TeammemberName" },
        { title: "Project", value: "ProjectName" },
        { title: "Customer", value: "CustomerName" },
        { title: "Commitment", value: "CommitmentName" },
        { title: "Type", value: "CommitmentType" },
        { title: "Hours", value: "TotalHours" },
        { title: "Actions", sortable: false, value: "Actions" },
      ],
    };
  },
  computed: {
    dateRangeText(): string {
      const retVal = `${Utils.toVsDateFormat(this.selectedDateRange[0])} ~ ${Utils.toVsDateFormat(
        this.selectedDateRange[this.selectedDateRange.length - 1]
      )}`;
      if (this.selectedDateRange.length > 1) {
        this.datePickerMenu = false;
      }
      return retVal;
    },
    startDate(): string {
      return this.selectedDateRange[0];
    },
    endDate(): string {
      return this.selectedDateRange[this.selectedDateRange.length - 1];
    },
  },
  methods: {
    decimalCount(num: any) {
      const numStr = String(num);
      if (numStr.includes(".")) {
        return numStr.split(".")[1].length;
      }
      return 0;
    },
    clearFilters() {
      this.selectedCustomer = "";
      this.selectedCommitment = "";
      this.selectedTeammember = "";
    },
    timesheet(model: any) {
      this.selectedHrCommtimentReport = model;
      this.showTimesheet = true;
    },
    async createReport() {
      if (this.selectedDateRange.length < 2) {
        return;
      }
      this.isLoading = true;
      const dates = this.dateRangeText.split("~");
      await this.fetchHrCommitmentReport(Utils.vsDateToDatetime(dates[0]), Utils.vsDateToDatetime(dates[1]));
      this.isLoading = false;
    },
    async fetchCustomers() {
      this.customersArrays = await CustomerService.getList(true);
      this.customers = this.customersArrays
        .filter((c) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim()))
        .map((item) => {
          return { id: item.id, text: `${item.FirstName} ${item.LastName}` };
        });
    },
    async fetchCommitment() {
      if (this.selectedCustomer) {
        this.commitmentsArrays = await CommitmentService.getCommitmentsByCustomerId(this.selectedCustomer);
        this.commitments = this.commitmentsArrays.map((item) => {
          return { id: item.id, text: item.Name };
        });
      }
    },
    async fetchTeammembers() {
      const commitmentsObject = this.commitmentsArrays.find((c: any) => c.id === this.selectedCommitment);
      if (commitmentsObject) {
        const commimentTeammembersByCustomers = commitmentsObject.TeamMembers.filter((c: any) => c.TmFullName);
        if (commimentTeammembersByCustomers && commimentTeammembersByCustomers.length > 0) {
          this.teammembers = commimentTeammembersByCustomers.map((item: any) => {
            return { id: item.id, text: `${item.TmFullName}` };
          });
        }
      }
    },
    async fetchHrCommitmentReport(startDate: any, endDate: any) {
      this.hrCommitmentsReport = await HRCommitmentReportService.getList(
        startDate,
        endDate,
        this.selectedCustomer,
        this.selectedCommitment,
        this.selectedTeammember
      );
      this.hrCommitmentsReport.sort((a, b) => {
        if (a.TeammemberName < b.TeammemberName) {
          return -1;
        }
        if (a.TeammemberName > b.TeammemberName) {
          return 1;
        }
        return 0;
      });
      this.filteredHrCommitmentsReport = this.hrCommitmentsReport;
    },
    selectedRange(newVal: string) {
      let startDate = new Date();
      let endDate = new Date();
      switch (newVal) {
        case "This Week":
          startDate = moment().startOf("week").toDate();
          endDate = moment().endOf("week").toDate();
          break;
        case "Last Week":
          startDate = moment().subtract(1, "weeks").startOf("week").toDate();
          endDate = moment().subtract(1, "weeks").endOf("week").toDate();
          break;
        case "Last Month":
          startDate = moment().subtract(1, "months").startOf("month").toDate();
          endDate = moment().subtract(1, "months").endOf("month").toDate();
          break;
        case "This Month":
          startDate = moment().startOf("month").toDate();
          endDate = moment().endOf("month").toDate();
          break;
        case "Last Year":
          startDate = moment().subtract(1, "years").startOf("year").toDate();
          endDate = moment().subtract(1, "years").endOf("year").toDate();
          break;
        case "This Year":
          startDate = moment().startOf("year").toDate();
          endDate = moment().endOf("year").toDate();
          break;
      }
      if (startDate < endDate) {
        (this.selectedDateRange as any) = [startDate, endDate];
      }
    },
  },
  watch: {
    async selectedCustomer(newVal) {
      this.selectedCommitment = "";
      if (newVal) {
        const customerObject = this.customersArrays.find((c) => c.id === newVal);
        await this.fetchCommitment();
      } else {
        this.commitments = [];
      }
    },
    selectedCommitment(newVal) {
      this.selectedTeammember = "";
      if (newVal) {
        this.fetchTeammembers();
      } else {
        this.teammembers = [];
      }
    },
  },
  async mounted() {
    await this.fetchCustomers();
  },
});
</script>
