<template>
  <div>
    <AnalyticalReport
      ref="analyticalReport"
      :customers="customers"
      :reportLoading="reportLoading"
      @createReport="createReport"
      :timesheets="timesheets"
      v-if="customers.length > 0"
    ></AnalyticalReport>
  </div>
</template>
<script lang="ts">
import AnalyticalReport from "shared-components/src/components/Reports/Analytical/AnalyticalReport.vue";
import { defineComponent } from "vue";
import Utils from "shared-components/src/utils/Utils";
import ActivityService from "shared-components/src/services/ActivityService";
import CustomerService from "shared-components/src/services/CustomerService";
import TimesheetService from "shared-components/src/services/TimesheetService";
import { Activity } from "shared-components/src/models/Activity";
import store from "@/store";

export default defineComponent({
  data() {
    return {
      customers: [] as Array<{ id: string; text: string }>,
      timesheets: [] as any[],
      activities: [] as Activity[],
      reportLoading: false,
    };
  },
  components: {
    AnalyticalReport,
  },
  methods: {
    getActivityName(activityId: string | null): string {
      if (activityId) return this.activities.find((item) => item.id == activityId)?.Value ?? "";
      return "";
    },
    async createReport(data: any): Promise<void> {
      try {
        this.reportLoading = true;
        const dates = data.dateRange.split("~");
        const items = await TimesheetService.getByCustomer(data.customer, Utils.vsDateToDatetime(dates[0]), Utils.vsDateToDatetime(dates[1]));
        this.timesheets = items.filter(
          (x: any) =>
            (x && x.Activity && x.Activity.trim().length > 0) ||
            (this.getActivityName(x.ActivityId) && this.getActivityName(x.ActivityId).trim().length > 0)
        );
        this.reportLoading = false;
      } catch (ex) {
        store.dispatch("showErrorMessage", ex);
      } finally {
        this.reportLoading = false;
      }
    },
  },
  async mounted() {
    this.activities = await ActivityService.getActivityList();
    try {
      CustomerService.getList()
        .then((items: any) => {
          this.customers = items
            .filter((c: any) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim()))
            .map((item: any) => {
              return { id: item.id, text: `${item.FirstName} ${item.LastName}` };
            });
        })
        .catch((err: any) => {
          store.dispatch("showErrorMessage", err);
        });
    } catch (ex) {
      store.dispatch("showErrorMessage", ex);
    }
  },
});
</script>
