<template>
  <v-card>
    <v-card-title>
      <div class="card-title">
        <div class="card-title-left">VM Support</div>
        <div class="card-title-right">
          <v-btn color="primary" dark @click="showNewSupportModal = true" prepend-icon="mdi-plus"> New Support Notification</v-btn>
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table-server 
        :items="supportList" 
        :headers="headers" 
        :loading="loading" 
        :page="paginationModel.page"
        :items-per-page="paginationModel.numberOfPages"
        @update:options="(event: any) => tableOptions = event"
        :items-length="paginationModel.totalItemsCount"
        item-key="Id">
          <template v-slot:item.ShortDesc="{ item }">
            {{ item.Title }}
          </template>
          <template v-slot:item.Sender="{ item }">
            {{ titleCase(item.Sender ?? "") }}
          </template>
          <template v-slot:item.CreatedAt="{ item }">
            {{ formatDate(item.CreatedOn) }}
          </template>
          <template v-slot:item.LastReplyOn="{ item }">
            {{ formatDate(item.LastReplyOn) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu offset-y location="bottom" scroll-strategy="close">
              <template v-slot:activator="{ props }">
                <v-btn size="small" variant="text" color="black" v-bind="props" icon="mdi-dots-vertical" />
              </template>
              <v-list>
                <v-list-item @click="viewItem(item.Id)" prepend-icon="mdi-comment-edit-outline " title="View" />
              </v-list>
            </v-menu>
          </template>
        </v-data-table-server>
    </v-card-text>
    <v-dialog width="600" v-model="showNewSupportModal" @keypress.esc="closeNewSupportModal">
      <VdSupport
        @cancel="closeNewSupportModal"
        @sendRequest="createSupportNotification"
        :isSupport="true"
      />
    </v-dialog>
    <v-dialog width="800" v-model="showViewSupportModal" @keypress.esc="closeViewSupportModal">
      <ViewSupportItem
        @close="closeViewSupportModal"
        :supportId="selectedSupportId"
      />
    </v-dialog>
    
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import CreateOrEditVM from "./CreateOrEditVM.vue";
import VdSupport from "shared-components/src/components/Vm/VdSupport.vue";
import { AdminSupportNotificationItemModel, NotificationApi, PaginationRequestModel } from "shared-components/src/services/openApi/api";
import store from "@/store";
import moment from "moment";
import ViewSupportItem from "./ViewSupportItem.vue";

export default defineComponent({
  components: { ConfirmDialog, CreateOrEditVM, VdSupport, ViewSupportItem },
  async mounted() {
    //await this.fetchSupportList();
  },
  data() {
    return {
      showNewSupportModal: false,
      showViewSupportModal: false,
      paginationModel: {
        page: 1,
        totalItemsCount: 0 as number | undefined,
        numberOfPages: 10,
        sortProp: "CreatedAt",
        sortOrder: "desc",
      },
      tableOptions: {} as any,
      tmLoading: true,
      loading: false,
      supportList: [] as AdminSupportNotificationItemModel[],
      selectedSupportId: null as string | null,
      headers: [
        { title: "Title", value: "ShortDesc", sortable: true },
        { title: "Sender", value: "Sender", width: 200 },
        { title: "Message", value: "Message" },
        { title: "Created on", value: "CreatedAt", width: 200, sortable: true },
        { title: "Last Reply On", value: "LastReplyOn", width: 200},
        { title: "Action", value: "action", align: "end" },
      ],
    };
  },
  computed: {
    isSupport(): boolean {
      return store.getters.userIsSupport ?? false;
    },
  },
  methods: {
    closeNewSupportModal(){
      this.showNewSupportModal = false;
    },
    closeViewSupportModal(){
      this.showViewSupportModal = false;
    },
    async createSupportNotification(event: any){
      try{
          const requestModel = {Message: event.Message, Title: event.Title} as AdminSupportNotificationItemModel
          await new NotificationApi().creatSupportNotifications(requestModel)
          this.showNewSupportModal = false;
          store.dispatch("showSuccessMessage", "Vd support request submitted successfully");
          await this.fetchSupportList();
        }catch{
          store.dispatch("showErrorMessage", "An error occurred while submitting the Vd support request");
        }
        finally{

        }
    },
    viewItem(itemId: string){
      this.selectedSupportId = itemId;
      this.showViewSupportModal = true;
    },
    async fetchSupportList() {
      try {
        this.loading = true;
        const { page, itemsPerPage } = this.tableOptions;

        const paginationModel = {
          PageNumber: page,
          PageSize: itemsPerPage,
          SortOrder: this.paginationModel.sortOrder,
          SortProp: this.paginationModel.sortProp,
        } as PaginationRequestModel;
        const result = (await (new NotificationApi().getSupportNotifications(paginationModel))).data;
        this.supportList = result.Items as AdminSupportNotificationItemModel[];
        this.paginationModel.totalItemsCount = result.Total;
      }
      finally {
        this.loading = false;
      }
    },
    formatDate(strDate: string){
      if(strDate){
        return moment(strDate).format("YYYY-MM-DD HH:MM:ss")
      }
      return "-";
    },
    titleCase(str: string) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      return splitStr.join(' '); 
    }
  },
  watch: {
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.paginationModel.sortProp = newVal.sortBy[0].key;
          this.paginationModel.sortOrder = newVal.sortBy[0].order;
        }
        this.fetchSupportList();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.selected-row{
  background-color: red;
}
.vm-checkbox{
  height: 50px;
}
.vm-row:hover {
  cursor: pointer;
}
.custom-timesheet-row {
  padding: 10px !important;
  .col {
    overflow-wrap: anywhere;
    text-align: center;
    padding: 0 8px;
  }
  .timesheet-row {
    border: 1px solid #959595;
    border-radius: 10px;
    padding: 10px 0;
    margin: 10px 0;

    .description {
      text-align: left;
      margin: 0 20px;
    }
  }
}
</style>
