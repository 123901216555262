import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withKeys as _withKeys } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "v-data-table-header" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "v-data-table-header" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { style: {"margin-bottom":"0px"} }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_WeekDetail = _resolveComponent("WeekDetail")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_CurrencyTextField = _resolveComponent("CurrencyTextField")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_loading_component = _resolveComponent("loading-component")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_PayRunDetail = _resolveComponent("PayRunDetail")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        (_ctx.showAddOrEdit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  (_ctx.selectedTeammember)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_v_dialog, {
                          modelValue: _ctx.showAddOrEdit,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showAddOrEdit) = $event)),
                          class: _normalizeClass(["main-dialog", `${_ctx.fullScreenMode ? 'fullscreen' : ''}`]),
                          fullscreen: _ctx.fullScreenMode,
                          persistent: "",
                          transition: "dialog-bottom-transition"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_toolbar, {
                                  dark: "",
                                  color: "primary"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                      icon: "",
                                      dark: "",
                                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeAddOrEditPayrunPopup()))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                                            _createTextVNode("mdi-close")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_toolbar_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.isEditMode ? "Edit Pay Run" : "New Pay Run") + " For " + _toDisplayString(`${_ctx.selectedTeammember.FirstName} ${_ctx.selectedTeammember.LastName}`), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_spacer),
                                    _createVNode(_component_v_toolbar_items, null, {
                                      default: _withCtx(() => [
                                        (_ctx.fullScreenMode && !_ctx.viewPayRun)
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 0,
                                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPdfDialog())),
                                              class: "secondary"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                    _createTextVNode("mdi-file-pdf")
                                                  ])),
                                                  _: 1
                                                }),
                                                _cache[10] || (_cache[10] = _createTextVNode(" Show PDF"))
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        (_ctx.fullScreenMode && !_ctx.viewPayRun)
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 1,
                                              dark: "",
                                              variant: "text",
                                              loading: _ctx.loading,
                                              disabled: !_ctx.valid || _ctx.loading,
                                              class: "primary",
                                              onClick: _ctx.save
                                            }, {
                                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                                _createTextVNode(" Save ")
                                              ])),
                                              _: 1
                                            }, 8, ["loading", "disabled", "onClick"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1)),
                                (!_ctx.fullScreenMode && !_ctx.isEditMode)
                                  ? (_openBlock(), _createBlock(_component_v_row, {
                                      key: 0,
                                      class: "m-0"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, {
                                          md: "8",
                                          sm: "8",
                                          cols: "12"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_VueDatePicker, {
                                              modelValue: _ctx.selectedMonth,
                                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedMonth) = $event)),
                                              "month-picker": "",
                                              "auto-apply": ""
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, {
                                          md: "4",
                                          sm: "4",
                                          cols: "12"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_btn, {
                                              class: "secondary",
                                              "x-large": "",
                                              onClick: _ctx.processRun,
                                              loading: _ctx.loadingInfo,
                                              disabled: _ctx.loadingInfo
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                                                    _createTextVNode("mdi-sync")
                                                  ])),
                                                  _: 1
                                                }),
                                                _cache[13] || (_cache[13] = _createTextVNode(" Process "))
                                              ]),
                                              _: 1
                                            }, 8, ["onClick", "loading", "disabled"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    (_ctx.model.Items && _ctx.model.Items.length && !_ctx.loadingInfo)
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                          _createVNode(_component_v_form, {
                                            ref: "frmPayRun",
                                            modelValue: _ctx.valid,
                                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.valid) = $event)),
                                            "lazy-validation": ""
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_row, { class: "m-0" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, { md: "12" }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_data_table, {
                                                        "hide-default-footer": "",
                                                        "hide-default-header": "",
                                                        "item-class": _ctx.SumRowBackground,
                                                        headers: _ctx.commitmentHeaders,
                                                        "items-per-page": -1,
                                                        items: _ctx.commitmentItems,
                                                        "item-key": "CommitmentName",
                                                        class: "elevation-1 custom-table-row"
                                                      }, {
                                                        thead: _withCtx(() => [
                                                          _createElementVNode("thead", _hoisted_3, [
                                                            _createElementVNode("tr", null, [
                                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commitmentHeaders, (head) => {
                                                                return (_openBlock(), _createElementBlock("th", {
                                                                  key: head.title,
                                                                  class: "text-center break-line"
                                                                }, [
                                                                  _createElementVNode("span", null, _toDisplayString(head.title), 1)
                                                                ]))
                                                              }), 128))
                                                            ])
                                                          ])
                                                        ]),
                                                        item: _withCtx(({ item }) => [
                                                          _createElementVNode("tr", {
                                                            class: _normalizeClass(['main-tr', item.IsSum ? 'sum-background' : ''])
                                                          }, [
                                                            _createElementVNode("td", null, _toDisplayString(item.CommitmentName), 1),
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.Weeks, (week, index) => {
                                                              return (_openBlock(), _createElementBlock("td", { key: index }, [
                                                                (item.IsSum || (week.Days && week.Days.length > 0))
                                                                  ? (_openBlock(), _createBlock(_component_WeekDetail, {
                                                                      key: 0,
                                                                      Week: week
                                                                    }, null, 8, ["Week"]))
                                                                  : _createCommentVNode("", true)
                                                              ]))
                                                            }), 128)),
                                                            _createElementVNode("td", null, _toDisplayString(item.TotalHours), 1),
                                                            _createElementVNode("td", null, [
                                                              (!item.IsSum)
                                                                ? (_openBlock(), _createBlock(_component_TextField, {
                                                                    key: 0,
                                                                    dense: "",
                                                                    outlined: "",
                                                                    modelValue: item.AdjustmentHours,
                                                                    "onUpdate:modelValue": [($event: any) => ((item.AdjustmentHours) = $event), ($event: any) => (_ctx.computeItem(item))],
                                                                    rules: [_ctx.rules.required, _ctx.rules.float],
                                                                    readonly: _ctx.isReadOnly || _ctx.viewPayRun,
                                                                    "hide-details": ""
                                                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "readonly"]))
                                                                : _createCommentVNode("", true),
                                                              (item.IsSum)
                                                                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.AdjustmentHours), 1))
                                                                : _createCommentVNode("", true)
                                                            ]),
                                                            _createElementVNode("td", null, [
                                                              (!item.IsSum)
                                                                ? (_openBlock(), _createBlock(_component_TextField, {
                                                                    key: 0,
                                                                    rules: [_ctx.rules.required, _ctx.rules.float],
                                                                    dense: "",
                                                                    outlined: "",
                                                                    modelValue: item.SeniorityLevel,
                                                                    "onUpdate:modelValue": [($event: any) => ((item.SeniorityLevel) = $event), ($event: any) => (_ctx.computeItem(item))],
                                                                    readonly: _ctx.isReadOnly || _ctx.viewPayRun,
                                                                    "hide-details": ""
                                                                  }, null, 8, ["rules", "modelValue", "onUpdate:modelValue", "readonly"]))
                                                                : (_openBlock(), _createElementBlock("span", _hoisted_5, "-"))
                                                            ]),
                                                            _createElementVNode("td", null, _toDisplayString(_ctx.GetFormatTwoDecimal(item.TotalPay)) + " AUD", 1)
                                                          ], 2)
                                                        ]),
                                                        _: 1
                                                      }, 8, ["item-class", "headers", "items"])
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }),
                                              _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
                                              (_ctx.selectedTeammember.IsLinemanager)
                                                ? (_openBlock(), _createBlock(_component_v_row, {
                                                    key: 0,
                                                    class: "m-0"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _cache[14] || (_cache[14] = _createElementVNode("h3", { class: "pl-3" }, "Line Manager's Commitments", -1)),
                                                      _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                                                      _createVNode(_component_v_col, { md: "12" }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_data_table, {
                                                            headers: _ctx.lmCommitmentHeaders,
                                                            items: _ctx.lmCommitmentItems,
                                                            "item-value": "Commitment.id",
                                                            class: "elevation-1 custom-table-row",
                                                            "items-per-page": -1,
                                                            "hide-default-footer": "",
                                                            "hide-default-header": "",
                                                            "item-class": _ctx.SumRowBackground
                                                          }, {
                                                            thead: _withCtx(() => [
                                                              _createElementVNode("thead", _hoisted_6, [
                                                                _createElementVNode("tr", null, [
                                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lmCommitmentHeaders, (head) => {
                                                                    return (_openBlock(), _createElementBlock("th", {
                                                                      key: head.title,
                                                                      class: "text-center break-line"
                                                                    }, [
                                                                      _createElementVNode("span", null, _toDisplayString(head.title), 1)
                                                                    ]))
                                                                  }), 128))
                                                                ])
                                                              ])
                                                            ]),
                                                            item: _withCtx(({ item }) => [
                                                              _createElementVNode("tr", {
                                                                class: _normalizeClass(['main-tr', item.IsSum ? 'sum-background' : ''])
                                                              }, [
                                                                _createElementVNode("td", null, _toDisplayString(item.Teammembers), 1),
                                                                _createElementVNode("td", null, _toDisplayString(item.CommitmentName), 1),
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.Weeks, (week, index) => {
                                                                  return (_openBlock(), _createElementBlock("td", { key: index }, [
                                                                    (item.IsSum || (week.Days && week.Days.length > 0))
                                                                      ? (_openBlock(), _createBlock(_component_WeekDetail, {
                                                                          key: 0,
                                                                          Week: week
                                                                        }, null, 8, ["Week"]))
                                                                      : _createCommentVNode("", true)
                                                                  ]))
                                                                }), 128)),
                                                                _createElementVNode("td", null, _toDisplayString(item.TotalHours), 1),
                                                                _createElementVNode("td", null, [
                                                                  (!item.IsSum)
                                                                    ? (_openBlock(), _createBlock(_component_TextField, {
                                                                        key: 0,
                                                                        modelValue: item.AdjustmentHours,
                                                                        "onUpdate:modelValue": [($event: any) => ((item.AdjustmentHours) = $event), ($event: any) => (_ctx.lmComputeItem(item))],
                                                                        rules: [_ctx.rules.required, _ctx.rules.float],
                                                                        dense: "",
                                                                        outlined: "",
                                                                        readonly: _ctx.isReadOnly || _ctx.viewPayRun,
                                                                        "hide-details": ""
                                                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "readonly"]))
                                                                    : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.AdjustmentHours), 1))
                                                                ]),
                                                                _createElementVNode("td", null, [
                                                                  (!item.IsSum)
                                                                    ? (_openBlock(), _createBlock(_component_TextField, {
                                                                        key: 0,
                                                                        modelValue: item.SeniorityLevel,
                                                                        "onUpdate:modelValue": [($event: any) => ((item.SeniorityLevel) = $event), ($event: any) => (_ctx.lmComputeItem(item))],
                                                                        rules: [_ctx.rules.required, _ctx.rules.float],
                                                                        dense: "",
                                                                        outlined: "",
                                                                        readonly: _ctx.isReadOnly || _ctx.viewPayRun,
                                                                        "hide-details": ""
                                                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "readonly"]))
                                                                    : (_openBlock(), _createElementBlock("span", _hoisted_8, "-"))
                                                                ]),
                                                                _createElementVNode("td", null, _toDisplayString(_ctx.GetFormatTwoDecimal(item.TotalPay)) + " AUD", 1)
                                                              ], 2)
                                                            ]),
                                                            _: 1
                                                          }, 8, ["headers", "items", "item-class"])
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  }))
                                                : _createCommentVNode("", true),
                                              _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
                                              _createVNode(_component_v_row, { class: "m-0" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, {
                                                    cols: "6",
                                                    md: "6",
                                                    sm: "12"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_row, null, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_col, {
                                                            md: "8",
                                                            sm: "7",
                                                            cols: "12"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_ComboBoxField, {
                                                                modelValue: _ctx.exchangeCurrency,
                                                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.exchangeCurrency) = $event)),
                                                                onChange: _ctx.onExchangeCurrencyChange,
                                                                items: _ctx.currencies,
                                                                "item-title": "name",
                                                                "item-value": "id",
                                                                dense: "",
                                                                outlined: "",
                                                                label: "Exchange Currency",
                                                                readonly: _ctx.viewPayRun
                                                              }, null, 8, ["modelValue", "onChange", "items", "readonly"])
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, {
                                                            md: "3",
                                                            sm: "4",
                                                            cols: "12"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_TextField, {
                                                                dense: "",
                                                                outlined: "",
                                                                label: "Exchange Rate",
                                                                modelValue: _ctx.ExchangeRateMoney,
                                                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ExchangeRateMoney) = $event)),
                                                                readonly: _ctx.isReadOnly || _ctx.viewPayRun
                                                              }, null, 8, ["modelValue", "readonly"])
                                                            ]),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_row, { class: "m-0" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, {
                                                    cols: "6",
                                                    md: "6",
                                                    sm: "12"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.audAdjustments, (ad, idx) => {
                                                        return (_openBlock(), _createBlock(_component_v_row, {
                                                          key: `aud_adjsmnt_${idx}`
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_col, {
                                                              md: "5",
                                                              sm: "5",
                                                              cols: "12"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_CurrencyTextField, {
                                                                  rules: [_ctx.rules.required, _ctx.rules.currency],
                                                                  dense: "",
                                                                  outlined: "",
                                                                  label: "Adjustments AUD",
                                                                  modelValue: ad.adjustment,
                                                                  "onUpdate:modelValue": ($event: any) => ((ad.adjustment) = $event),
                                                                  readonly: _ctx.isReadOnly || _ctx.viewPayRun
                                                                }, null, 8, ["rules", "modelValue", "onUpdate:modelValue", "readonly"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              md: "5",
                                                              sm: "5",
                                                              cols: "12"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_TextField, {
                                                                  dense: "",
                                                                  outlined: "",
                                                                  label: "Adjustment Description",
                                                                  value: ad.description,
                                                                  "onUpdate:modelValue": ($event: any) => (_ctx.setAdjustmentDesc($event, ad.id)),
                                                                  readonly: _ctx.isReadOnly || _ctx.viewPayRun
                                                                }, null, 8, ["value", "onUpdate:modelValue", "readonly"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              md: "1",
                                                              sm: "1",
                                                              cols: "12",
                                                              class: "mt-11"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (idx === _ctx.audAdjustments.length - 1 && !_ctx.viewPayRun)
                                                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                                                      key: 0,
                                                                      fab: "",
                                                                      "x-small": "",
                                                                      rounded: "",
                                                                      color: "primary",
                                                                      onClick: _ctx.addAudAjustment
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_v_icon, null, {
                                                                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                                                                            _createTextVNode("mdi-plus")
                                                                          ])),
                                                                          _: 1
                                                                        })
                                                                      ]),
                                                                      _: 1
                                                                    }, 8, ["onClick"]))
                                                                  : _createCommentVNode("", true),
                                                                (idx !== _ctx.audAdjustments.length - 1 && !_ctx.viewPayRun)
                                                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                                                      key: 1,
                                                                      fab: "",
                                                                      "x-small": "",
                                                                      rounded: "",
                                                                      color: "error",
                                                                      dark: "",
                                                                      onClick: ($event: any) => (_ctx.removeAdjustment(ad.id))
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_v_icon, null, {
                                                                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                                                                            _createTextVNode("mdi-delete")
                                                                          ])),
                                                                          _: 1
                                                                        })
                                                                      ]),
                                                                      _: 2
                                                                    }, 1032, ["onClick"]))
                                                                  : _createCommentVNode("", true)
                                                              ]),
                                                              _: 2
                                                            }, 1024)
                                                          ]),
                                                          _: 2
                                                        }, 1024))
                                                      }), 128))
                                                    ]),
                                                    _: 1
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "6",
                                                    md: "6",
                                                    sm: "12"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.secondaryAdjustments, (ad, idx) => {
                                                        return (_openBlock(), _createBlock(_component_v_row, {
                                                          key: `secondary_adjsmnt_${idx}`
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_col, {
                                                              md: "5",
                                                              sm: "5",
                                                              cols: "12"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_CurrencyTextField, {
                                                                  rules: [_ctx.rules.required, _ctx.rules.currency],
                                                                  dense: "",
                                                                  outlined: "",
                                                                  label: `Adjustments ${_ctx.exchangeCurrencyId}`,
                                                                  modelValue: ad.adjustment,
                                                                  "onUpdate:modelValue": ($event: any) => ((ad.adjustment) = $event),
                                                                  readonly: _ctx.isReadOnly || _ctx.viewPayRun
                                                                }, null, 8, ["rules", "label", "modelValue", "onUpdate:modelValue", "readonly"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              md: "5",
                                                              sm: "5",
                                                              cols: "12"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_TextField, {
                                                                  dense: "",
                                                                  outlined: "",
                                                                  label: "Adjustment Description",
                                                                  value: ad.description,
                                                                  "onUpdate:modelValue": ($event: any) => (_ctx.setAdjustmentDesc($event, ad.id)),
                                                                  readonly: _ctx.isReadOnly || _ctx.viewPayRun
                                                                }, null, 8, ["value", "onUpdate:modelValue", "readonly"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              md: "1",
                                                              sm: "1",
                                                              cols: "12",
                                                              class: "mt-11"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (idx === _ctx.secondaryAdjustments.length - 1 && !_ctx.viewPayRun)
                                                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                                                      key: 0,
                                                                      fab: "",
                                                                      "x-small": "",
                                                                      rounded: "",
                                                                      color: "primary",
                                                                      onClick: _ctx.addSecondaryAjustment
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_v_icon, null, {
                                                                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                                                                            _createTextVNode("mdi-plus")
                                                                          ])),
                                                                          _: 1
                                                                        })
                                                                      ]),
                                                                      _: 1
                                                                    }, 8, ["onClick"]))
                                                                  : _createCommentVNode("", true),
                                                                (idx !== _ctx.secondaryAdjustments.length - 1 && !_ctx.viewPayRun)
                                                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                                                      key: 1,
                                                                      fab: "",
                                                                      "x-small": "",
                                                                      rounded: "",
                                                                      color: "error",
                                                                      dark: "",
                                                                      onClick: ($event: any) => (_ctx.removeAdjustment(ad.id))
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_v_icon, null, {
                                                                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                                                                            _createTextVNode("mdi-delete")
                                                                          ])),
                                                                          _: 1
                                                                        })
                                                                      ]),
                                                                      _: 2
                                                                    }, 1032, ["onClick"]))
                                                                  : _createCommentVNode("", true)
                                                              ]),
                                                              _: 2
                                                            }, 1024)
                                                          ]),
                                                          _: 2
                                                        }, 1024))
                                                      }), 128))
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }),
                                              _cache[24] || (_cache[24] = _createElementVNode("hr", null, null, -1)),
                                              _createVNode(_component_v_row, { class: "m-0" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, {
                                                    cols: "6",
                                                    md: "6",
                                                    sm: "12"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loanItems, (li, idx) => {
                                                        return (_openBlock(), _createBlock(_component_v_row, {
                                                          key: `loan_item_${idx}`
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_col, {
                                                              md: "5",
                                                              sm: "5",
                                                              cols: "12"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_CurrencyTextField, {
                                                                  rules: [_ctx.rules.required, _ctx.rules.currency],
                                                                  dense: "",
                                                                  outlined: "",
                                                                  label: "Loan Installment AUD",
                                                                  modelValue: li.Amount,
                                                                  "onUpdate:modelValue": ($event: any) => ((li.Amount) = $event),
                                                                  readonly: _ctx.isReadOnly || _ctx.viewPayRun
                                                                }, null, 8, ["rules", "modelValue", "onUpdate:modelValue", "readonly"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              md: "6",
                                                              sm: "6",
                                                              cols: "12"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_TextField, {
                                                                  dense: "",
                                                                  outlined: "",
                                                                  label: "Loan Installment Description",
                                                                  value: li.Description,
                                                                  "onUpdate:modelValue": ($event: any) => (_ctx.setLoanItemDesc($event, li.LoanId)),
                                                                  readonly: _ctx.isReadOnly || _ctx.viewPayRun,
                                                                  disabled: li.IsDeleted
                                                                }, null, 8, ["value", "onUpdate:modelValue", "readonly", "disabled"])
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              md: "1",
                                                              sm: "1",
                                                              cols: "12",
                                                              class: "mt-11 text-center"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (li.IsDeleted && !_ctx.viewPayRun)
                                                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                                                      key: 0,
                                                                      fab: "",
                                                                      "x-small": "",
                                                                      rounded: "",
                                                                      color: "primary",
                                                                      onClick: ($event: any) => (_ctx.includeLoanItem(li.LoanId))
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_v_icon, null, {
                                                                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                                                                            _createTextVNode("mdi-plus")
                                                                          ])),
                                                                          _: 1
                                                                        })
                                                                      ]),
                                                                      _: 2
                                                                    }, 1032, ["onClick"]))
                                                                  : _createCommentVNode("", true),
                                                                (!li.IsDeleted && !_ctx.viewPayRun)
                                                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                                                      key: 1,
                                                                      fab: "",
                                                                      "x-small": "",
                                                                      rounded: "",
                                                                      color: "error",
                                                                      dark: "",
                                                                      onClick: ($event: any) => (_ctx.removeLoanItem(li.LoanId))
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_v_icon, null, {
                                                                          default: _withCtx(() => _cache[21] || (_cache[21] = [
                                                                            _createTextVNode("mdi-delete")
                                                                          ])),
                                                                          _: 1
                                                                        })
                                                                      ]),
                                                                      _: 2
                                                                    }, 1032, ["onClick"]))
                                                                  : _createCommentVNode("", true)
                                                              ]),
                                                              _: 2
                                                            }, 1024),
                                                            _createVNode(_component_v_col, {
                                                              md: "11",
                                                              sm: "11",
                                                              cols: "12",
                                                              style: {"margin-top":"-45px","font-size":"12px","font-style":"italic"}
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createElementVNode("p", _hoisted_9, " Total Amount: " + _toDisplayString(_ctx.formatNumber(li.LoanTotalAmount)) + " AUD,Total Paid Amount: " + _toDisplayString(_ctx.formatNumber(li.LoanTotalPaidAmount)) + " AUD, Total Un-Paid Amount: " + _toDisplayString(_ctx.formatNumber(li.LoanTotalUnPaidAmount)) + " AUD, Last Paid Amount: " + _toDisplayString(_ctx.formatNumber(li.LoanLastPaidInstallmentAmount)) + " AUD ", 1)
                                                              ]),
                                                              _: 2
                                                            }, 1024)
                                                          ]),
                                                          _: 2
                                                        }, 1024))
                                                      }), 128))
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_v_row, { class: "m-0" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, {
                                                    cols: "6",
                                                    md: "6",
                                                    sm: "12"
                                                  }),
                                                  _createVNode(_component_v_col, {
                                                    cols: "6",
                                                    md: "6",
                                                    sm: "12"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_row, { style: {"padding-top":"18px"} }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_col, {
                                                            md: "3",
                                                            sm: "4",
                                                            cols: "12"
                                                          }),
                                                          _createVNode(_component_v_col, {
                                                            md: "4",
                                                            sm: "4",
                                                            cols: "12"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createElementVNode("h3", null, "Total pay: " + _toDisplayString(_ctx.GetFormatTwoDecimal(_ctx.totalPayment)) + " AUD", 1)
                                                            ]),
                                                            _: 1
                                                          }),
                                                          _createVNode(_component_v_col, {
                                                            md: "5",
                                                            sm: "4",
                                                            cols: "12"
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createElementVNode("h3", null, "Total pay in " + _toDisplayString(_ctx.exchangeCurrencyId) + " : " + _toDisplayString(_ctx.GetFormatTwoDecimal(_ctx.totalPaymentLC)), 1)
                                                            ]),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }),
                                              (_ctx.paymentMethodDetail)
                                                ? (_openBlock(), _createElementBlock("hr", _hoisted_10))
                                                : _createCommentVNode("", true),
                                              _createElementVNode("div", { innerHTML: _ctx.paymentMethodDetail }, null, 8, _hoisted_11)
                                            ]),
                                            _: 1
                                          }, 8, ["modelValue"]),
                                          (_ctx.pageLoading)
                                            ? (_openBlock(), _createBlock(_component_loading_component, { key: 0 }))
                                            : _createCommentVNode("", true)
                                        ], 64))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue", "class", "fullscreen"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.showPdf && _ctx.payRun != null)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          "max-width": "800",
          modelValue: _ctx.showPdf,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showPdf) = $event)),
          key: `PDF-Modal-${_ctx.pdfVersion}`,
          persistent: "",
          onKeydown: _withKeys(_ctx.closePdfDialog, ["esc"])
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_PayRunDetail, {
              model: _ctx.payRun,
              isForAdmin: true,
              onConfirmed: _ctx.payRunConfirmed,
              key: `PDF-${_ctx.pdfVersion}`,
              payrunAuditTrail: _ctx.payrunAuditTrail,
              onClose: _ctx.closePdfDialog
            }, null, 8, ["model", "onConfirmed", "payrunAuditTrail", "onClose"]))
          ]),
          _: 1
        }, 8, ["modelValue", "onKeydown"]))
      : _createCommentVNode("", true)
  ]))
}