import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "card-title-right" }
const _hoisted_3 = { class: "v-card-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.loading,
    disabled: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-title-left" }, "New Billable Item", -1)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_btn, {
                variant: "text",
                size: "small",
                icon: "mdi-close",
                onClick: _ctx.close
              }, null, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.valid) = $event)),
            ref: "frmBilling"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.model.Name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.Name) = $event)),
                        label: "Name",
                        placeholder: "Name"
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SelectField, {
                        items: _ctx.billableItemCodes,
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.model.Code,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.Code) = $event)),
                        label: "Code",
                        placeholder: "Code"
                      }, null, 8, ["items", "rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SelectField, {
                        items: _ctx.billableItemIntervals,
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.model.Interval,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.Interval) = $event)),
                        label: "Interval",
                        placeholder: "Interval"
                      }, null, 8, ["items", "rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        rules: [_ctx.rules.required, _ctx.rules.minNumber(0)],
                        modelValue: _ctx.model.Fee,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.Fee) = $event)),
                        type: "number",
                        min: "0",
                        label: "Fee",
                        placeholder: "Fee"
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              class: "right",
              color: "secondary",
              onClick: _ctx.save,
              loading: _ctx.loading,
              disabled: _ctx.loading,
              "prepend-icon": "mdi-check"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Add")
              ])),
              _: 1
            }, 8, ["onClick", "loading", "disabled"]),
            _createVNode(_component_v_btn, {
              variant: "text",
              color: "accent",
              class: "right mr-5",
              onClick: _ctx.close,
              disabled: _ctx.loading
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("cancel")
              ])),
              _: 1
            }, 8, ["onClick", "disabled"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}