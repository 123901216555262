<template>
  <div>
    <template v-if="loading"> Loading customers, please wait... </template>
    <template v-else>
      <div >
        <v-row v-for="customer in customers" :key="customer.id" @mouseover="customerHovered(customer.id)" align="center" class="flowContainer">
          <v-col md="3" sm="6" :class="getCustomerProjects(customer.id).length ? 'customer' : ''">
            <span class="rectCard" @click="customerDetail(customer)">
              {{ `${customer.FirstName} ${customer.LastName}` }}
            </span>
          </v-col>
          <v-col dense md="9" sm="6" :class="getCustomerProjects(customer.id).length ? 'project' : ''">
            <div v-for="project in getCustomerProjects(customer.id)" :key="project.id" class="projectName">
              <v-row class="rectCard" @click="projectDetail(project)">
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="10">
                      <h4 class="pb-0">
                        {{ project.Name }}
                      </h4>
                    </v-col>
                    <v-col cols="2" class="text-right">
                      <v-btn variant="text" size="small" icon="mdi-database-plus " v-tooltip="'Epic List'" @click.stop="showEpicsMenu(project)"/>
                    </v-col>
                  </v-row>
                </v-col>
                
                <v-col class="pt-0 pl-0 pb-0" v-if="getCommitmentLeads(project) && getCommitmentLeads(project).length > 0">
                  <v-col class="pt-0 pl-0 pb-0">
                    <span class="projectDetailTitle"> Leads: </span>
                  </v-col>
                  <v-row class="pt-4 pl-3">
                    <v-col class="pt-0 pl-0 pb-0">
                      <span class="tmName tinfo" v-for="l in getCommitmentLeads(project)" :key="l.Name">
                        <span class="tinfo teammemberText">{{ l.Name }}</span>
                        <span class="tinfo ml-2 mr-1">
                          <v-img v-ripple class="tmImage" :src="l.Photo | profileImage" aspect-ratio="1" />
                        </span>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="teamMemberList pt-0 pl-0 pb-0" v-if="getCommitmentTeammembers(project) && getCommitmentTeammembers(project).length > 0">
                  <v-col class="pt-2 pl-0 pb-0">
                    <span class="projectDetailTitle"> Team members: </span>
                  </v-col>
                  <v-row class="pt-4 pl-3">
                    <v-col class="pt-0 pl-0 pb-0">
                      <span class="tmName tinfo" v-for="t in getCommitmentTeammembers(project)" :key="t.Name">
                        <span class="tinfo teammemberText">{{ t.Name }}</span>
                        <span class="tinfo ml-2 mr-1">
                          <v-img
                            v-ripple
                            class="tmImage"
                            :style="{ 'border-color': statusColor(t.Status) }"
                            :src="t.Photo | profileImage"
                            aspect-ratio="1"
                          />
                        </span>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div class="projectName" :id="customer.id" v-if="hoveredCustomerId === customer.id" @click="projectDetail()">
              <span class="rectCard addProjectBut"> New Project <v-icon>mdi-plus</v-icon> </span>
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
    <v-dialog v-if="showCustomerDetail" v-model="showCustomerDetail" fullscreen @keydown.esc="showCustomerDetail = false">
      <customer-detail :showClose="true" :customer="selectedCustomer" @close="showCustomerDetail = false"></customer-detail>
    </v-dialog>
    <v-dialog v-if="showProjectDetail" v-model="showProjectDetail" @keydown.esc="showProjectDetail = false" max-width="700">
      <project-detail
        :commitments="projectCommitments"
        :teammembers="projectTeammembers"
        :repos="projectRepositories"
        :users="users"
        :project="selectedProject"
        :customerId="hoveredCustomerId"
        @close="showProjectDetail = false"
        @savedProject="savedProject"
      >
      </project-detail>
    </v-dialog>
    <v-dialog v-model="showEpicList" max-width="700">
      <EpicList
        @close="showEpicList = false"
        :project="selectedProject"
      />
    </v-dialog>
  </div>
</template>
<script lang="ts">
import Project from "shared-components/src/models/Project";
import CustomerService from "shared-components/src/services/CustomerService";
import RepositoryService from "shared-components/src/services/RepositoryService";
import CommitmentService from "shared-components/src/services/CommitmentService";
import ProjectService from "shared-components/src/services/ProjectService";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import CustomerDetail from "shared-components/src/components/Customer/CustomerDetails.vue";
import ProjectDetail from "shared-components/src/components/Project/ProjectDetails.vue";
import EpicList from "shared-components/src/components/Project/EpicList.vue";
import UserService from "@/services/UserService";
import { defineComponent } from "vue";
import Commitment from "shared-components/src/models/Commitment";
import Repository from "shared-components/src/models/Repository";
import { Teammember } from "shared-components/src/models/Teammember";
import ManagerModel from "shared-components/src/models/ManagerModel";
import store from "@/store";

export default defineComponent({
  components: { CustomerDetail, ProjectDetail, EpicList },
  data() {
    return {
      hoveredCustomerId: "",
      isProjectUpdated: true,
      users: [] as any,
      loading: false,
      showCustomerDetail: false,
      showProjectDetail: false,
      showEpicList: false,
      customers: [] as ManagerModel[],
      repositories: [] as Repository[],
      projectRepositories: [] as Repository[],
      projects: [] as Project[],
      filteredProjects: [] as Project[],
      selectedCustomer: null as ManagerModel | null,
      selectedProject: null as Project | null,
      commitments: [] as Commitment[],
      teammembers: [] as Teammember[],
      projectTeammembers: [] as Teammember[],
      projectCommitments: [] as Commitment[],
    };
  },
  methods: {
    showEpicsMenu(project: any){
      this.selectedProject = project;
      this.showEpicList = true;
    },
    fetchTeammembers(): Promise<Teammember[]> {
      return TeamMemberService.getList();
    },
    fetchRepositories(): Promise<Repository[]> {
      return RepositoryService.getList();
    },
    fetchCustomers(): Promise<ManagerModel[]> {
      return CustomerService.getList();
    },
    fetchProjects(): Promise<Project[]> {
      return ProjectService.getList(true);
    },
    getCustomerProjects(customerId: string): Project[] {
      return this.projects.filter((proj) => proj.Customerss && proj.Customerss.includes(customerId));
    },
    savedProject(model: Project): void {
      const exist = this.projects.some((c) => c.id === model.id);
      if (!exist) {
        this.projects.push(model);
      }
      if(this.selectedProject)
      {
        this.selectedProject.Name = model.Name;
      }
      this.isProjectUpdated = true;
      this.showProjectDetail = false;
    },
    customerHovered(id: any) {
      this.hoveredCustomerId = id;
    },
    customerDetail(customer: ManagerModel): void {
      this.selectedCustomer = customer;
      store.dispatch("showLoading");
      setTimeout(() => {
        store.dispatch("hiddenLoading");
        this.showCustomerDetail = true;
      }, 500);
    },
    getCommitmentLeads(project: Project): Array<{ Name: string; Photo: string }> {
      const retVal = [] as Array<{ Name: string; Photo: string }>;
      if (this.users) {
        this.users
          .filter((u: any) => project.ProjectLead && project.ProjectLead.includes(u.uid))
          .map((u: any) => {
            if (u.displayName && !retVal.some((i) => i === u.displayName)) {
              const findTeamMember = this.teammembers.find((c) => c.UserAccountsId === u.uid);
              const uPhoto = findTeamMember && findTeamMember.PhotoUrl ? findTeamMember.PhotoUrl : "";
              retVal.push({ Name: u.displayName, Photo: uPhoto });
            }
          });
      }

      return retVal;
    },
    statusColor(status: any) {
      switch (status) {
        case 0:
          return "#00cd00";
        case 3:
          return "yellow";
        default:
          return "red";
      }
    },
    getCommitmentTeammembers(project: Project): Array<{ Name: string; Photo: string }> {
      const retVal = [] as Array<{ Name: string; Photo: string; Status: number }>;
      const projectCommitments = this.commitments.filter((c) => c.ProjectIds && c.ProjectIds.some((i: any) => i === project.id));
      projectCommitments.forEach((c: any) => {
        if (c.TeamMembers) {
          c.TeamMembers.forEach((t: any) => {
            if (t.TmFullName && !retVal.some((i) => i.Name === t.TmFullName)) {
              const findTeamMember = this.teammembers.find((item) => item.Id === t.id);
              let tmPhoto = "";
              let tmStatus = 2;
              if (findTeamMember) {
                const teamMemberCommitments = projectCommitments.filter((pc) => pc.TeamMemberIds.includes(findTeamMember.Id));
                if (teamMemberCommitments && teamMemberCommitments.length > 0) {
                  const activeCommitmetns = teamMemberCommitments.some((tc) => this.checkDate(tc) === 0);
                  const upcommingCommitmetns = teamMemberCommitments.some((tc) => this.checkDate(tc) === 3);
                  if (activeCommitmetns) {
                    tmStatus = 0;
                  } else if (upcommingCommitmetns) {
                    tmStatus = 3;
                  }
                }
                tmPhoto = findTeamMember.PhotoUrl ? findTeamMember.PhotoUrl : "";
              }
              retVal.push({ Name: t.TmFullName, Photo: tmPhoto, Status: tmStatus });
            }
          });
        }
      });
      return retVal;
    },
    checkDate(item: any) {
      if (!item.IsActive) {
        return 2; // Inactive
      }
      const today = new Date();
      if (item.EndDate && item.EndDate < today) {
        return 1; // Expired
      }
      if (item.StartDate && item.StartDate > today) {
        return 3; // Upcoming
      }
      return 0; // Active
    },
    projectDetail(project: Project | null): void {
      this.isProjectUpdated = false;
      this.projectTeammembers = [];
      if (project) {
        this.projectRepositories = this.repositories.filter((c) => project.Repositories && project.Repositories.includes(c.Id));
        this.projectCommitments = this.commitments.filter((c) => c.ProjectIds.includes(project.id));
        this.projectCommitments.forEach((c) => {
          const tm = this.teammembers.filter((item) => c.TeamMemberIds.includes(item.Id));
          this.projectTeammembers.push(...tm);
        });
        this.projectTeammembers = [...new Set(this.projectTeammembers)];
      }
      if ((project && !project.Repositories) || !project) {
        this.projectRepositories = [] as Repository[];
      }
      this.selectedProject = project;
      this.showProjectDetail = true;

      // store.dispatch("showLoading");
      // setTimeout(() => {
      //   store.dispatch("hiddenLoading");
      //   this.showProjectDetail = true;
      // }, 500);
    },
  },
  mounted(): void {
    this.loading = true;
    this.fetchTeammembers().then((tm) => {
      this.teammembers = tm.filter((c) => c.FirstName && c.LastName);
    });
    this.fetchRepositories().then((repos) => {
      this.repositories = repos.filter((c) => c.HttpsLink);
    });
    this.fetchCustomers()
      .then((customers) => {
        this.customers = customers;
        this.fetchProjects()
          .then((projects) => {
            this.projects = projects;
            UserService.getAllUsers()
              .then((users) => {
                this.users = users;
                CommitmentService.getList()
                  .then((commitments: any) => {
                    this.commitments = commitments;
                    this.loading = false;
                  })
                  .catch((commitmentError: any) => {
                    store.dispatch("showErrorMessage", "There is some error in fetching commitments!")
                  });
              })
              .catch((userError) => {
                store.dispatch("showErrorMessage", "There is some error in fetching users!")
              });
          })
          .catch((projectError) => {
            store.dispatch("showErrorMessage", "There is some error in fetching projects!")
          });
      })
      .catch((customerError) => {
        this.loading = false;
        store.dispatch("showErrorMessage", "There is some error in fetching customers!")
      });
  },
  watch: {
    showProjectDetail(newVal) {
      if (newVal) {
        //document.documentElement.style.overflow = "hidden";
      } else {
        //document.documentElement.style.overflow = "auto";
        //this.isProjectUpdated = true;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.tinfo {
  float: left;
}
.tmImage {
  border: 3px solid;
  border-radius: 15px;
  width: 30px;
  height: 30px;
}
.addProjectBut {
  border-color: rgb(255, 00, 00, 0.5) !important;
}
.flowContainer {
  margin: 5px 0;

  &:hover {
    .customer,
    .project,
    .projectName {
      &::after,
      &::before {
        background: red !important;
        box-shadow: 0 0 3px 3px rgb(255, 00, 00, 0.3);
      }
    }
  }
  .customer {
    position: relative;
    &::after {
      content: " ";
      width: 100%;
      background: silver;
      height: 1px;
      z-index: 1;
      position: absolute;
      top: 50%;
    }
  }

  .project {
    position: relative;
    background: #fff;
    z-index: 2;
    padding: 0;

    .projectName {
      position: relative;

      &:not(:first-child) {
        padding-top: 10px;
      }

      &::after {
        content: " ";
        position: absolute;
        height: 1px;
        background: silver;
        left: 0;
        top: 50%;
        width: 50px;
      }

      &::before {
        content: " ";
        position: absolute;
        background: silver;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
      }

      &:first-child {
        &::before {
          top: 50%;
        }
      }

      &:last-child {
        &::before {
          bottom: 50%;
        }
      }

      &:first-child {
        &:last-child {
          &::before {
            display: none;
          }
        }
      }
    }
    .rectCard {
      position: relative;
      background: #fff;
      border: 1px solid silver;
      border-radius: 10px;
      display: inline-block;
      margin: 0 0 0 50px;
      padding: 10px;
      z-index: 2;
    }
  }
  .rectCard {
    &:hover {
      box-shadow: 0 0 3px 3px rgb(255, 00, 00, 0.3);
    }

    position: relative;
    background: #fff;
    border: 1px solid silver;
    border-radius: 10px;
    display: inline-block;
    margin: 0;
    padding: 10px;
    z-index: 2;
    cursor: pointer;
  }
  .teammemberText {
    line-height: 29px;
  }

  .projectDetailTitle {
    font-size: 14px;
  }

  .tmName {
    height: 40px;
    font-size: 12px;

    &::after {
      content: ",";
      margin-right: 5px;
    }

    &:last-child {
      &::after {
        content: "";
      }
    }
  }
}
</style>
