import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.vmModel && _ctx.vmModel.Id ? 'Edit Vm' : 'Register Vm'), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "frmRegister",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.valid) = $event)),
            "lazy-validation": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.selectedTeammember,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTeammember) = $event)),
                        items: _ctx.teammemberList,
                        "item-title": "FullName",
                        "item-value": "Id",
                        outlined: "",
                        dense: "",
                        label: "Team Member",
                        "return-object": ""
                      }, {
                        item: _withCtx(({ item, index, props }) => [
                          _createVNode(_component_v_list, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item, _mergeProps(props, {
                                class: { 'txt-color': item.raw.isFirstInactive },
                                title: item.raw.FullName
                              }), null, 16, ["class", "title"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 1
                      }, 8, ["rules", "modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        rules: [_ctx.rules.required],
                        outlined: "",
                        dense: "",
                        label: "User Name",
                        placeholder: "User Name",
                        modelValue: _ctx.model.Username,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.Username) = $event))
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        rules: [_ctx.rules.required],
                        outlined: "",
                        dense: "",
                        label: "Password",
                        placeholder: "Password",
                        modelValue: _ctx.model.TempPassword,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.TempPassword) = $event))
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        rules: [_ctx.rules.required],
                        outlined: "",
                        dense: "",
                        label: "Public IP",
                        placeholder: "Public IP",
                        modelValue: _ctx.model.PublicIP,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.PublicIP) = $event))
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        rules: [_ctx.rules.required],
                        outlined: "",
                        dense: "",
                        label: "Private IP",
                        placeholder: "Private IP",
                        modelValue: _ctx.model.PrivateIP,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.PrivateIP) = $event))
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        type: "number",
                        rules: [_ctx.rules.required],
                        outlined: "",
                        dense: "",
                        label: "Port",
                        placeholder: "Port",
                        modelValue: _ctx.model.RemotePortRdp,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.RemotePortRdp) = $event))
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DateInputField, {
                        label: "Provisioned On",
                        "first-day-of-week": "1",
                        modelValue: _ctx.model.ProvisionedOn,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.ProvisionedOn) = $event)),
                        "hide-actions": "",
                        placeholder: "Provisioned On",
                        "prepend-icon": ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        type: "number",
                        rules: [_ctx.rules.required],
                        outlined: "",
                        dense: "",
                        label: "CPU",
                        placeholder: "CPU",
                        modelValue: _ctx.model.CPU,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.CPU) = $event))
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        type: "number",
                        rules: [_ctx.rules.required],
                        outlined: "",
                        dense: "",
                        label: "RAM",
                        placeholder: "RAM",
                        modelValue: _ctx.model.RAM,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.RAM) = $event))
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        outlined: "",
                        dense: "",
                        label: "Wazuh Agent Id",
                        placeholder: "Wazuh Agent Id",
                        modelValue: _ctx.model.WazuhAgentId,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.WazuhAgentId) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AutoCompleteField, {
                        label: "Wazuh Level",
                        modelValue: _ctx.model.WazuhLevel,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.WazuhLevel) = $event)),
                        items: _ctx.wazuhLevels,
                        multiple: "",
                        outlined: "",
                        chips: "",
                        dense: ""
                      }, null, 8, ["modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        outlined: "",
                        dense: "",
                        label: "Wazuh Policy Id",
                        placeholder: "Wazuh Policy Id",
                        modelValue: _ctx.model.WazuhPolicyId,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.WazuhPolicyId) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "align-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        type: "number",
                        rules: [_ctx.rules.required],
                        outlined: "",
                        dense: "",
                        label: "Storage",
                        placeholder: "Storage",
                        modelValue: _ctx.model.Storage,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.Storage) = $event))
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_checkbox, {
                        "hide-details": "",
                        modelValue: _ctx.model.Activitywatch,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.Activitywatch) = $event)),
                        label: "Activitywatch"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_checkbox, {
                        "hide-details": "",
                        modelValue: _ctx.model.AdminAccess,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.AdminAccess) = $event)),
                        label: "AdminAccess"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_checkbox, {
                        "hide-details": "",
                        modelValue: _ctx.model.CodeClanAgent,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.CodeClanAgent) = $event)),
                        label: "CodeClanAgent"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_checkbox, {
                        "hide-details": "",
                        modelValue: _ctx.model.GuestAgent,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.GuestAgent) = $event)),
                        label: "GuestAgent"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { md: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_checkbox, {
                        "hide-details": "",
                        modelValue: _ctx.model.WazuhAgent,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.WazuhAgent) = $event)),
                        label: "WazuhAgent"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "secondary",
                class: "mr-10",
                onClick: _ctx.save,
                loading: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createTextVNode("mdi-check")
                    ])),
                    _: 1
                  }),
                  _cache[20] || (_cache[20] = _createTextVNode(" Save"))
                ]),
                _: 1
              }, 8, ["onClick", "loading"]),
              _createVNode(_component_v_btn, {
                variant: "text",
                color: "primary",
                onClick: _ctx.cancel,
                loading: _ctx.loading
              }, {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createTextVNode(" cancel")
                ])),
                _: 1
              }, 8, ["onClick", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading"]))
}