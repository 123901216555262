<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            Project Leads
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="newItem"> <v-icon>mdi-plus</v-icon> New Project Lead </v-btn>
            <v-dialog v-if="showNewItem" v-model="showNewItem" persistent max-width="600" @keydown.esc="showNewItem = false">
              <new-project-lead ref="newProjectLead" :selectedLead="selectedLead" @cancel="showNewItem = false" @saved="saved"></new-project-lead>
            </v-dialog>
            <v-dialog v-if="showLoginLinkDialog" v-model="showLoginLinkDialog" persistent max-width="600" @keydown.esc="cancelLoginLinkDialog">
              <v-card>
                <v-card-title> Login Link For {{ selectedFullName }} </v-card-title>
                <v-card-text>
                  <a :href="selectedLoginLink" target="_blank">{{ selectedLoginLink }}</a>
                </v-card-text>
                <v-card-actions>
                  <v-col md="12">
                    <v-btn class="secondary" @click="cancelLoginLinkDialog"><v-icon>mdi-cancel</v-icon> cancel</v-btn>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-overlay :absolute="true" :opacity="0.3" :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-data-table :items="leads" :headers="headers" :loading="loading">
            <template v-slot:item.id="{ item }">
              <strong>{{ item.id }}</strong>
            </template>
            <template v-slot:item.FirstName="{ item }">
              <span>{{ item.FirstName }}</span>
            </template>
            <template v-slot:item.LastName="{ item }">
              <span>{{ item.LastName }}</span>
            </template>
            <template v-slot:item.Email="{ item }">
              {{ item.Email }}
            </template>
            <template v-slot:item.AppliedCustomerName="{ item }">
              {{ item.AppliedCustomerName }}
            </template>
            <template v-slot:item.IsActive="{ item }">
              <v-checkbox hide-details readonly v-model="item.IsActive" />
            </template>
            <template v-slot:item.Actions="{ item }">
              <v-menu offset-y location="bottom" scroll-strategy="close">
                <template v-slot:activator="{ props }">
                  <v-btn size="small" variant="text" color="black" v-bind="props" icon="mdi-dots-vertical" />
                </template>
                <v-list>
                  <v-list-item @click="edit(item)" prepend-icon="mdi-pencil-outline" title="Edit" />
                  <v-list-item @click="getLoginLink(item)" prepend-icon="mdi-eye" title="Show Login Link" />
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ProjectLeadService from "shared-components/src/services/ProjectLeadService";
import NewProjectLead from "./NewProjectLead.vue";
import ProjectLead from "shared-components/src/models/ProjectLead";
import UserService from "@/services/UserService";
import store from "@/store";

export default defineComponent({
  components: {
    NewProjectLead,
  },
  data() {
    return {
      showConfirm: false,
      deleteLoading: false,
      loading: false,
      leads: [] as ProjectLead[],
      showNewItem: false,
      showLoginLinkDialog: false,
      selectedLoginLink: "",
      selectedFullName: "",
      selectedLead: {} as ProjectLead,
      confirmModel: {
        title: "",
        text: "",
      },
      headers: [
        { title: "ID", align: "start", value: "id" },
        { title: "First Name", value: "FirstName" },
        { title: "Last Name", value: "LastName" },
        { title: "Email", value: "Email" },
        { title: "Customer", value: "AppliedCustomerName" },
        { title: "Is Active", value: "IsActive" },
        { title: "Actions", sortable: false, value: "Actions" },
      ],
    };
  },
  async mounted() {
    this.loading = true;
    await this.loadItems();
    this.loading = false;
  },
  methods: {
    async loadItems() {
      this.leads = await ProjectLeadService.getList();
    },
    edit(item: any) {
      this.selectedLead = item;
      this.showNewItem = true;
    },
    getLoginLink(item: any) {
      this.loading = true;
      UserService.getLoginLinkForClient(item.Email)
        .then((result: any) => {
          if (result.status) {
            this.selectedLoginLink = result.data;
            this.showLoginLinkDialog = true;
            this.selectedFullName = `${item.FirstName} ${item.LastName}`;
          } else {
            store.dispatch("showErrorMessage", result.message);
          }
        })
        .catch((error: any) => {
          store.dispatch("showErrorMessage", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelLoginLinkDialog() {
      this.selectedLoginLink = "";
      this.showLoginLinkDialog = false;
      this.selectedFullName = "";
    },
    newItem() {
      this.selectedLead = {} as ProjectLead;
      this.showNewItem = true;
    },
    saved(item: ProjectLead) {
      this.loadItems();
      this.showNewItem = false;
    },
  },
});
</script>
